<div class="note">
  <span>*Note: To build a Pump Schedule with advanced placement methods, please create the schedule in
    iCem and then import into VIDA.</span>
</div>
<div class="flx-ctn-row-rev"
  [ngClass]="{'disable-form': (disabledOnControlPointState$ | async) || disabledOnJobState, 'disable-state': disabledOnJobState}">

  <div class="flx-itm-3-12 flx-itm-xs-12-12 flx-ctn-col p-t-20">
    <div class="flx-itm p-b-25 editing">
      <i class="glyphicon glyphicon-envelope custom-icon" aria-hidden="true"></i>
      <span (click)="sendMailReport()" class="custom-span">Report SAP Mismapping</span>
    </div>
    <shoetrack [listPumpScheduleStageStateManager$]="listPumpScheduleStageStateManager$"
      [scheduleState]="scheduleState"
      [namePumpSchedule]="getNamePumpSchedule"
      [isCmtSvc]="job.cmtSvc">
    </shoetrack>
  </div>
  <div class="flx-itm-9-12 flx-itm-xs-12-12 flx-ctn-col p-t-20 p-r-20 pump-stages">
    <div class="center-tabs">
      <p-tabView *ngIf="(isAddPumpScheduleVisible$ | async)"
        [class]="'ui-tab-view-md p-tab-view-lg pump-schedule-tab-view '+ getButtonAddClass()"
        [(activeIndex)]="selectedTab" (onChange)="onTabChange($event)">
        <ng-container *ngFor="let pumpScheduleTabview of (listPumpScheduleStageStateManager$ | async)">
          <p-tabPanel [selected]="pumpScheduleTabview.index === selectedTab">
            <ng-template pTemplate="header">
              <div class="p-3 pump-name-container" [title]="pumpScheduleTabview.displayText"
                [*ngIf]="!pumpScheduleTabview.isHidden">
                <div class="pump-schedule-name"
                  contenteditable="false">
                  {{pumpScheduleTabview.displayText}}
                </div>
                <span *ngIf="pumpScheduleTabview.index === selectedTab && enabledDeletePumpScheduleState"
                  class='pump-tab-delete-btn' (click)="showPumpScheduleOption(pumpScheduleTabview, pumpOption, $event)">
                  <i class="pi pi-ellipsis-v"></i>
                </span>
              </div>
            </ng-template>
          </p-tabPanel>
        </ng-container>
      </p-tabView>
      <div>
        <stage class="stage-view" *ngFor="let stageState of (stagesStates$ | async) as stageStateNodes;let indexOfStage=index;"
          [stageState]="stageState" [refreshForm]="refreshForm"
          [slurryData]="slurryData"
          [index]="indexOfStage"
          [isPumpDefault]="selectedTab === 0"
          [job]="job"
          (onStageAlign)="onStageAlign($event)"></stage>
      </div>
      <div class="tabview-add-new"
        *ngIf="pumpScheduleStageStateManager.length < 12 && (isAddPumpScheduleVisible$ | async) && (enabledAddPumpScheduleState$ | async)"
        class="tabview-add-new" (click)="addPumpScheduleBtnOnClicked()">
        <i class="fa fa-plus-circle"></i> Add Pump Schedule
      </div>
    </div>
  </div>
</div>
<p-menu #pumpOption [popup]="true" [model]="pumpScheduleOptions" appendTo="body" baseZIndex="100000000"></p-menu>

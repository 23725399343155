import { UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { CustomValidators } from '../../../apps/vida/src/modules/shared/validators';

export class JobCasingEquipmentDescModel{
    sapMaterialNumber: string;
    sapMaterialDescription: string;
    productCode: string;
    productGroup: string;
    productFamily: string;
    productName: string;
    className: string;
    classDescription: string;
    casingSize: string;
    casingWeight: string;
    casingGrade: string;
    casingThread: string;
    maxTemperature: number;
    collapsePressure: number;
    burstPressure: number;
}

export class JobCasingEquipmentSalesOrderModel extends JobCasingEquipmentDescModel {
    numberOfItems: number;
    dataSource: string;
}

export class JobCasingEquipmentListItemModel extends JobCasingEquipmentDescModel {
    isAdded: boolean;
}

export class JobCasingEquipment extends JobCasingEquipmentDescModel {
    id: string;
    numberOfItems: number;
    surveyCompletionDate: string;
    dataSource: string;
    isMaxPressureNA: boolean = false;
    maxPressure: number;
    isCollapsePressureNA: boolean = false;
    collapsePressure: number;
    isBurstPressureNA: boolean = false;
    burstPressure: number;
    isMaxTemperatureNA: boolean = false;
    maxTemperature: number;
    isNewlyAdded: boolean = false;
    isNew: boolean = false;

    public static toForm(
        model: JobCasingEquipment,
        isCollapsePressureValid = true,
        isBurstPressureValid = true,
        isMaxTemperatureValid = true
    ): UntypedFormGroup {
        return new UntypedFormGroup({
            id:  new UntypedFormControl(model.id),
            productCode:new UntypedFormControl(model.productCode),
            productGroup: new UntypedFormControl(model.productGroup),
            productFamily: new UntypedFormControl(model.productFamily),
            productName: new UntypedFormControl(model.productName),
            numberOfItems: new UntypedFormControl(model.numberOfItems == null ? 0 : model.numberOfItems, Validators.required),
            sapMaterialNumber: new UntypedFormControl(model.sapMaterialNumber, Validators.required),
            sapMaterialDescription: new UntypedFormControl(model.sapMaterialDescription),
            classDescription: new UntypedFormControl(model.classDescription),
            className: new UntypedFormControl(model.className),
            casingSize: new UntypedFormControl(model.casingSize),
            casingWeight: new UntypedFormControl(model.casingWeight),
            casingGrade: new UntypedFormControl(model.casingGrade),
            casingThread: new UntypedFormControl(model.casingThread),
            dataSource: new UntypedFormControl(model.dataSource),
            collapsePressure: new UntypedFormControl(model.collapsePressure),
            isCollapsePressureNA: new UntypedFormControl(model.isCollapsePressureNA),
            burstPressure: new UntypedFormControl(model.burstPressure),
            isBurstPressureNA: new UntypedFormControl(model.isBurstPressureNA),
            maxTemperature: new UntypedFormControl(model.maxTemperature),
            isMaxTemperatureNA: new UntypedFormControl(model.isMaxTemperatureNA),
            isNew: new UntypedFormControl(model.isNew),
            isCollapsePressureValid: new UntypedFormControl(isCollapsePressureValid),
            isBurstPressureValid: new UntypedFormControl(isBurstPressureValid),
            isMaxTemperatureValid: new UntypedFormControl(isMaxTemperatureValid),
        });
    }

    public static toModel(fg: UntypedFormGroup): JobCasingEquipment {
        const res: JobCasingEquipment = fg.getRawValue();
        return res;
    }

    private static naRequiredValidator(ctrlName: string, naCtrlName: string): ValidatorFn {
        return (form: UntypedFormGroup): ValidationErrors | null => {
            const ctrl: UntypedFormControl = form.controls[ctrlName] as UntypedFormControl;
            const naCtrl: UntypedFormControl = form.controls[naCtrlName] as UntypedFormControl;
            if (!ctrl || !naCtrl) return null;
            const naValue: boolean = naCtrl.value;
            if (!naValue) {
                const ctrlErrors = CustomValidators.requiredWithTrimming(ctrl);
                ctrl.setErrors(ctrlErrors);
                if (ctrlErrors != null) {
                    return {
                        ctrlName: ctrlErrors
                    };
                }
            }
            return null;
        }
    }
}

<dash-page-header title="Active Jobs"></dash-page-header>

<div class="row header">
  <div class="col-md-2">
    <div class="search-bar">
      <input type="text" class="form-control input-search" placeholder="Search Active Jobs" #box
        (keyup.enter)="onSearch(box.value.trim())" />
      <div class="fa fa-search icon-search" (click)="onSearch(box.value.trim())"></div>
    </div>
  </div>

  <div class="col-md-6">
    <div class="col-md-12 input-switch">
      <p-inputSwitch (onChange)="handleChange($event)" [(ngModel)]="checked"></p-inputSwitch>
      <label> Filter by Current Location</label>
      <div class="col-md-6 popup-link" *ngIf="wellSize > 0">
        <a href="javascript: void(0)" (click)="showDialogWellImported()"><span>{{wellSize}}</span> wells from iDos have not been imported</a>
      </div>
    </div>

  </div>

  <div class="col-md-4 display-record" *ngIf="totalRecords">
    Displaying {{ (firstPage + 1) }} to {{ ((firstPage+rowsPerPage) < totalRecords) ? (firstPage + rowsPerPage) : totalRecords }} out of
    {{ totalRecords }} Job(s)
  </div>
</div>

<div class="saved-table m-bottom-2">
  <div styleclass="no-fixed-table" [loadingIndicator]="loadingSubscription">
    <p-table
      [value]="jobs"
      [columns]="cols"
      [paginator]="true"
      [lazy]="true"
      (onLazyLoad)="getPage($event)"
      [rows]="rowsPerPage"
      [totalRecords]="totalRecords"
      sortmode="single"
      [sortField]="sortField"
      [sortOrder]="sortOrder">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th style="width: 24px;"></th>
          <th *ngFor="let col of columns" [style.width]="col.width" [pSortableColumn]="col.field" [pSortableColumnDisabled]="!col.field">
            {{col.header}}

            <more-info-tooltip *ngIf="col.field === 'actualDate|projectedDate'">
              <i icon class="fa fa-info-circle"></i>
              <div message style="width: 150px">
                Projected Date is Editable<br/>
                Actual Date is Not Editable
              </div>
            </more-info-tooltip>

            <p-sortIcon *ngIf="!!col.field" [field]="col.field"></p-sortIcon>
          </th>
          <th style="width: 40px;"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-jobForm let-columns="columns">
        <tr>
          <td>
            <job-list-status-icons [jobForm]="jobForm"></job-list-status-icons>
          </td>
          <td>
            <a [routerLink]="['/job-simple', jobForm.job.id]">{{jobForm.job.jobCode}}</a>
          </td>
          <td [ngClass]="{ 'risk-level-background': jobForm.job.riskLevel > 3 }">
            {{jobForm.job.riskLevel}}
          </td>
          <td>
            {{jobForm.job.customerName}}
          </td>
          <td>
            <a class="show-underline" [routerLink]="['/wells']" [queryParams]="{id: jobForm.job.wellId}">{{jobForm.job.wellName}}</a>
          </td>
          <td>
            {{jobForm.job.wellNumber}}
          </td>
          <td>
            {{jobForm.job.rigName}}
          </td>
          <td>
            {{jobForm.job.jobName}}
          </td>
          <td>
            {{jobForm.job.jobTypeName}}
          </td>
          <td class="vertical-align-middle">
            <control-point-statuses [jobForm]="jobForm"></control-point-statuses>
          </td>
          <td>
            {{jobForm.job.jobStatusDescription}}
          </td>
          <td>
            <job-date [jobForm]="jobForm"> </job-date>
          </td>
          <td class="vertical-align-middle">
            <div *ngIf="jobForm.job.cmtSvc"
                 class="appian-status" [ngClass]="{'partially-pushed': jobForm.job.appianPushState === 2,
                                                     'fully-pushed': jobForm.job.appianPushState === 3}">
            </div>
          </td>
          <td class="vertical-align-middle">
            <div *ngIf="jobForm.job.cmtSvc" class="appian-status" openBulkBoard [ngClass]="{'partially-loaded': jobForm.job.appianBBLoadStatus === 1,
                                                                              'fully-loaded': jobForm.job.appianBBLoadStatus === 2}">
            </div>
          </td>
          <td>
            {{jobForm.job.modifiedDate | dateFormatWithLocalTimeZone: DATE_TIME_CALL_STANDARD}}
          </td>
          <td>
            <span style="display:flex; flex-direction: row;justify-content: space-between;" class="ng-star-inserted">
              <div class="dropdown">
                <button (click)="showAllCollectionNameActiveJobs(jobForm.job.id)" class="btn-link" type="button"
                  data-toggle="dropdown">
                  <i class="fa fa-ellipsis-h" aria-hidden="true"></i>
                </button>
                <ul class="dropdown-menu show-left">
                  <li *ngIf="!jobForm.job.isMyJob && !jobForm.job.inLibrary">
                    <a (click)="saveToLibrary(jobForm.job.id)"><i class="fa fa-plus" aria-hidden="true"></i> Add to Saved
                      Jobs</a>
                  </li>
                  <li *ngIf="!jobForm.job.isMyJob && jobForm.job.inLibrary">
                    <a (click)="removeJobFromSavedJobs(jobForm.job.id)"><i class="fa fa-times" aria-hidden="true"></i>
                      Remove from Saved Jobs</a>
                  </li>
                  <li>
                    <a [routerLink]="['/job-simple', jobForm.job.id]"><i class="fa fa-th-large" aria-hidden="true"></i> Go
                      to Dashboard</a>
                  </li>
                  <li *ngIf="collectionNameActiveJobs?.length" class="has-submenu">
                    <a><i class="fa fa-plus" aria-hidden="true"></i> Add to Collection </a>
                    <ul class="dropdown-menu">
                      <li *ngFor="let col of collectionNameActiveJobs">
                        <a (click)="addToCollectionActiveJobs(col.key, jobForm.job.id, col.value, jobForm.job.jobName)"
                          title="{{col.value}}">{{col.value}}</a>
                      </li>
                    </ul>
                  </li>
                  <li *ngIf="collectionNameActiveJobsAdded?.length" class="has-submenu">
                    <a>
                      <i class="fa fa-times" aria-hidden="true"></i> Remove from Collection </a>
                    <ul class="dropdown-menu">
                      <li *ngFor="let col of collectionNameActiveJobsAdded">
                        <a (click)="removeFromCollectionActiveJobs(col.key, jobForm.job.id, col.value, jobForm.job.jobName)"
                          title="{{col.value}}">{{col.value}}</a>
                      </li>
                    </ul>
                  </li>
                  <li *ngIf="jobForm.job.canChangeJobOwner">
                    <a (click)="changeJobOwner(jobForm.job.id, jobForm.job.jobOwner)">
                     <img src="../../../assets/images/person_search_black_24dp.svg"> Change Job Owner </a>
                  </li>
                </ul>
              </div>
            </span>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage" let-columns>
          <tr>
              <td [attr.colspan]="columns.length + 2">
                  No records found
              </td>
          </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<change-job-owner-dialog></change-job-owner-dialog>

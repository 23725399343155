<div [class.job-edit-test-result]="isJobEditView" [class.cp-test-result]="!isJobEditView">
  <div class="d-flex flex-wrap row-1">
    <div class="col-rheology">
      <rheology-test-result 
        [canEdit]="canEdit"
        [testTables]="testTables$ | async"
        [availableSlurries]="availableSlurries"
        (onChangeSlurryNo)="changeSlurryNo($event)"
        (onDeleteTest)="deleteTest($event)">
      </rheology-test-result>
    </div>
    <div class="col-tt">
      <thickening-time-test-result
        [canEdit]="canEdit"
        [testTables]="testTables$ | async"
        [availableSlurries]="availableSlurries"
        [stageState]="stageState"
        (onChangeSlurryNo)="changeSlurryNo($event)">
      </thickening-time-test-result>
    </div>
    <div class="col-uca">
      <uca-test-result
        [canEdit]="canEdit"
        [testTables]="testTables$ | async"
        [availableSlurries]="availableSlurries"
        (onChangeSlurryNo)="changeSlurryNo($event)">
      </uca-test-result>
    </div>
    <div class="col-free-fluid">
      <free-fluid-test-result 
        [canEdit]="canEdit"
        [testTables]="testTables$ | async"
        [availableSlurries]="availableSlurries"
        (onChangeSlurryNo)="changeSlurryNo($event)">
      </free-fluid-test-result>
    </div>
    <div class="col-sgs">
      <static-gel-strength-result [canEdit]="canEdit"
                              [testTables]="testTables$ | async"
                              [availableSlurries]="availableSlurries"
                              (onChangeSlurryNo)="changeSlurryNo($event)">

      </static-gel-strength-result> 
    </div>
    <div class="col-fluid-loss">
      <fluid-loss-test-result
        [canEdit]="canEdit"
        [testTables]="testTables$ | async"
        [availableSlurries]="availableSlurries"
        (onChangeSlurryNo)="changeSlurryNo($event)">
      </fluid-loss-test-result>
    </div>
  </div>
</div>

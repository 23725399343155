import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { environment } from "libs/environment";
import {
  CasingEquipmentSearchCriteria,
  ISAPMaterialModel,
  SearchSAPMaterialCaseItem,
  SELECTION_NULL
} from "libs/models";
import { JobCasingEquipment, JobCasingEquipmentDescModel } from "libs/models/entities/job-casing-equipment";
import { HttpService } from ".";

@Injectable()
export class JobCasingEquipmentService {
    constructor(
        private httpClient: HttpClient,
        private httpService: HttpService
      ) {
      }

    getSapMaterialClass(): Observable<{name: string, description: string}[]> {
        return this.httpService.Get(`${environment.baseUrl}/api/job-casing-equipment/sapmaterial_class`);
    }

    getSapMaterialCasingSize(group: string, family: string, product: string, className: string): Observable<string[]> {
        const pg = group ? encodeURI(group) : '';
        const pf = family ? encodeURI(family) : '';
        const pc = product ? encodeURI(product) : '';
        const cl = className? encodeURI(className) : '';
        return this.httpService.Get(`${environment.baseUrl}/api/job-casing-equipment/sapmaterial_casing_size?pg=${pg}&pf=${pf}&pc=${pc}&cl=${cl}`);
    }

    getSapMaterialCasingValues(group: string, family: string, product: string, className: string, casingSize: string): Observable<SearchSAPMaterialCaseItem[]> {
        const pg = group ? encodeURI(group) : '';
        const pf = family ? encodeURI(family) : '';
        const pc = product ? encodeURI(product) : '';
        const cl = className ? encodeURI(className) : '';
        const cz_n = casingSize == SELECTION_NULL;
        const cz = casingSize && !cz_n ? encodeURI(casingSize) : '';
        return this.httpService.Get(`${environment.baseUrl}/api/job-casing-equipment/sapmaterial_casing_values?pg=${pg}&pf=${pf}&pc=${pc}&cl=${cl}&cz=${cz}&cz_n=${cz_n}`);
    }

    getSapMaterialSearchItems(criteria: CasingEquipmentSearchCriteria): Observable<JobCasingEquipmentDescModel[]> {
        const pg = criteria.productGroup ? encodeURI(criteria.productGroup) : '';
        const pf = criteria.productFamily ? encodeURI(criteria.productFamily) : '';
        const pc = criteria.product ? encodeURI(criteria.product) : '';
        const cl = criteria.className ? encodeURI(criteria.className) : '';
        const cz_n = criteria.casingSize == SELECTION_NULL;
        const cz = criteria.casingSize && !cz_n ? encodeURI(criteria.casingSize) : '';

        const cw_n = criteria.casingWeight == SELECTION_NULL;
        const cw = criteria.casingWeight && !cw_n ? encodeURI(criteria.casingWeight) : '';
        const cg_n = criteria.casingGrade == SELECTION_NULL;
        const cg = criteria.casingGrade && !cg_n ? encodeURI(criteria.casingGrade) : '';
        const ct_n = criteria.casingThread == SELECTION_NULL;
        const ct = criteria.casingThread && !ct_n ? encodeURI(criteria.casingThread) : '';
        return this.httpService.Get(`${environment.baseUrl}/api/job-casing-equipment/sapmaterial_search_items?pg=${pg}&pf=${pf}&pc=${pc}&cl=${cl}&cz=${cz}&cz_n=${cz_n}&cw=${cw}&cw_n=${cw_n}&cg=${cg}&cg_n=${cg_n}&ct=${ct}&ct_n=${ct_n}`);
    }

    getSapMaterialDescriptionByKeyword(keyword: string): Observable<ISAPMaterialModel[]> {
        const key = encodeURI(keyword);
        return this.httpService.Get(`${environment.baseUrl}/api/job-casing-equipment/sapmaterial_search_description?key=${key}`);
    }

    getSapMaterialDescriptionByNumber(sapNumber: string): Observable<ISAPMaterialModel>{
        const key = encodeURI(sapNumber);
        return this.httpService.Get(`${environment.baseUrl}/api/job-casing-equipment/sapmaterial_get_description?num=${key}`);
    }

    getSapMaterialSearchItemByNumber(sapNumber: string): Observable<JobCasingEquipmentDescModel>{
        const key = encodeURI(sapNumber);
        return this.httpService.Get(`${environment.baseUrl}/api/job-casing-equipment/sapmaterial_search_item?num=${key}`);
    }
    
    getSapMaterialSearchCasingValues(): Observable<{casingSizes: string[], casingThreads: string[], casingGrades: string[], casingWeights: string[]}>{
        return this.httpService.Get(`${environment.baseUrl}/api/job-casing-equipment/sapmaterial_job_casing_values`);
    }

    public isMaxTemperatureValid(value: JobCasingEquipment, bhst: number): boolean {
        if (!bhst || value?.isMaxTemperatureNA) {
            return true;
        }
        if(value.maxTemperature !=null){
            return (bhst === value?.maxTemperature)
            || (bhst < value?.maxTemperature);
        }else{
            return true;
        }
        
    }

    public isCollapsePressureValid(value: JobCasingEquipment, jobMaxPressure: number): boolean {
        if (!jobMaxPressure || value?.isCollapsePressureNA) {
            return true;
        }

        return (jobMaxPressure === value?.collapsePressure)
            || (jobMaxPressure < value?.collapsePressure);
    }

    public isBurstPressureValid(value: JobCasingEquipment, jobMaxPressure: number): boolean {
        if (!jobMaxPressure || value?.isBurstPressureNA) {
            return true;
        }

        return (jobMaxPressure === value?.burstPressure)
            || (jobMaxPressure < value?.burstPressure)
    }
}

import { ViewportScroller } from "@angular/common";
import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Injectable({ providedIn: 'root' })
export class GoToService {
    constructor(
        private activatedRoute: ActivatedRoute,
        private scroller: ViewportScroller,
    ) { }

    goToSection() {
        this.scroller.scrollToAnchor(this.activatedRoute.snapshot.fragment);
    }
}
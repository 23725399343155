import { UnitType } from "libs/constants";
import { PumpScheduleStageTest, PumpScheduleStageTestTable } from "libs/models/ifact/ifacts-request-tests";
import { IFactTestName, TestStatusDescription, TestTableName, TestTableNameTestNamesMapping } from "./stage-test-results.constant";
import { FormControl } from "@angular/forms";
import { IFactsAvailableSlurry } from "libs/models/ifact/ifacts-available-slurry";

export class ChangeValueExport {
    constructor(
        public testTable: PumpScheduleStageTestTable,
        public testTables: PumpScheduleStageTestTable[],
    ){}
}

export abstract class PumpScheduleTestResultBase {
    protected testTable: PumpScheduleStageTestTable;
    private _testTables: PumpScheduleStageTestTable[];

    UnitType = UnitType;
    IFactTestName = IFactTestName;
    TestTableName = TestTableName;

    protected readonly testTableName: string;

    selectedSlurry = new FormControl();
    availableTestSlurries: number[] = [];

    get tests(): PumpScheduleStageTest[] {
        return this.testTable?.pumpScheduleStageTests?.filter(t => !t.isDeleted) || [];
    }

    constructor(testTableName: string) {
        this.testTableName = testTableName;
    }

    isTestFinishedOrValidated(test: PumpScheduleStageTest): boolean {
        return test?.testStatusDescription === TestStatusDescription.Finished || test?.testStatusDescription === TestStatusDescription.Validated;
    }

    getField(test: PumpScheduleStageTest, fieldName: string): string {
        return test?.pumpScheduleStageTestFields?.find(t => t.testFieldDesc === fieldName)?.result;
    }

    protected getChangeValueExport() {
        return new ChangeValueExport(this.testTable, this._testTables);
    }

    protected changeSlurryNo(slurryNo: number) {
        this.testTable.selectedSlurryNo = slurryNo;
    }

    protected deleteTest(test: PumpScheduleStageTest) {
        test.isDeleted = true;
    }

    protected initTestTable(value: PumpScheduleStageTestTable[]) {
        this._testTables = value;
        this.testTable = value?.find(t => t.name === this.testTableName);

        this.selectedSlurry.patchValue(this.testTable?.selectedSlurryNo || null);
        this.selectedSlurry.updateValueAndValidity();
    }

    protected initAvailableTestSlurries(value: IFactsAvailableSlurry[]) {
        this.availableTestSlurries = value?.filter(s => s.tests?.length && s.tests.some(t => TestTableNameTestNamesMapping[this.testTableName].includes(t.testName))).map(x => x.number).sort((a, b) => b - a) || [];
    }
}
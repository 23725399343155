import { NgModule, ModuleWithProviders } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { CPESharedModule } from '../shared/shared.module';
import { UIModule } from 'libs/ui';
import { PumpScheduleModule } from '../pump-schedule/pump-schedule.module';
import {
  ScheduleComponent,
  StageInfoComponent,
  StageComponent,
  StageEventComponent,
  StageFluidComponent,
  StageFluidMaterialComponent,
  ShoeTrackComponent,
  LinerComponent
} from './components';
import { VIDASharedModule } from 'libs/shared/vida-shared.module';
import { FluidsModule } from '../fluids/fluids.module';
import { ClickOutsideModule } from 'ng-click-outside';
import { TabViewModule } from 'primeng/tabview';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { InputMaskModule } from 'primeng/inputmask';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CheckboxModule } from 'primeng/checkbox';
import { ContextMenuModule } from 'primeng/contextmenu';
import {MenuModule} from 'primeng/menu';
import { PumpScheduleAdapter } from '../pump-schedule/adapters';
import { PumpScheduleService, PumpScheduleLogic, MudTypeService } from '../pump-schedule/services';
import { FluidsAdapter } from '../fluids/adapters';
import { SvgIconComponent } from 'apps/vida/src/modules/pump/components/mix-on-the-fly/svg-icon.component';
import { MixOnTheFlyComponent } from 'apps/vida/src/modules/pump/components/mix-on-the-fly/mix-on-the-fly.component';
import { JobTotalsComponent } from './components/job-totals/job-totals.component';
import { StageTestResultsComponent } from './components/stage-test-results/stage-test-results.component';
import { RheologyTestResultComponent } from './components/rheology-test-result/rheology-test-result.component';
import { FreeFluidTestResultComponent } from './components/free-fluid-test-result/free-fluid-test-result.component';
import { UcaTestResultComponent } from './components/uca-test-result/uca-test-result.component';
import { FluidLossTestResultComponent } from './components/fluid-loss-test-result/fluid-loss-test-result.component';
import { ThickeningTimeTestResultComponent } from './components/thickening-time-test-result/thickening-time-test-result.component';
import { StaticGelStrengthResultComponent } from './components/static-gel-strength-result/static-gel-strength-result.component';

const thirdPartyModules = [
  CommonModule,
  TabViewModule,
  AutoCompleteModule,
  InputMaskModule,
  OverlayPanelModule,
  CheckboxModule,
  ClickOutsideModule,
  ContextMenuModule,
  MenuModule
];

const services = [
  PumpScheduleService,
  PumpScheduleLogic,
  MudTypeService,
  FluidsAdapter
];

const adapters = [
  PumpScheduleAdapter
];

const components = [
  LinerComponent,
  ScheduleComponent,
  StageComponent,
  StageInfoComponent,
  StageEventComponent,
  StageFluidComponent,
  StageTestResultsComponent,
  StageFluidMaterialComponent,
  ShoeTrackComponent,
  SvgIconComponent,
  MixOnTheFlyComponent,
  JobTotalsComponent
];

@NgModule({
  imports: [
    ...thirdPartyModules,
    UIModule,
    CPESharedModule,
    VIDASharedModule,
    FluidsModule,
    FormsModule,
    PumpScheduleModule
  ],
  declarations: [
    ...components,
    RheologyTestResultComponent,
    FreeFluidTestResultComponent,
    UcaTestResultComponent,
    FluidLossTestResultComponent,
    StaticGelStrengthResultComponent,
    ThickeningTimeTestResultComponent,
  ],
  exports: [
    ...components
  ]
})
export class PumpModule {
  static forRoot(): ModuleWithProviders<PumpModule> {
    return {
      ngModule: PumpModule,
      providers: [
        ...services,
        ...adapters,
      ]
    };
  }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { Observable, Subscription } from 'rxjs';
import { map, shareReplay, switchMap, take } from 'rxjs/operators';

import { MATERIAL_TYPE, UnitType } from 'libs/constants';
import { FluidModel, ISAPMaterialModel, PumpScheduleStageMaterialModel } from 'libs/models';
import { FluidService, UpdateMaterialParameters, UserSettingService } from 'libs/shared';
import { ErrorMessageModel } from 'libs/ui/validations/error-message.model';
import { UnitConversionService } from '../../../../../../../libs/ui';
import { EditJobAdapter } from '../../../edit-job/adapters';
import { ControlPointType, ValidatorSetting } from '../../../shared/constant';
import { MaterialNumberAssignedFlag } from '../../../shared/constant/slurry-source';
import { FluidStateManager } from '../../state/fluid-state-manager';
import { FluidMaterialStateManager } from '../../state/material-state-manager';
import { StageStateManager } from '../../state/stage-state-manager';
import { ViewState } from '../../view-state';
import { PumpScheduleStateFactory } from '../../state/state-factory';

@Component({
  selector: 'stage-fluid-material',
  templateUrl: './stage-fluid-material.component.html',
  styleUrls: ['./stage-fluid-material.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StageFluidMaterialComponent implements OnInit, OnDestroy {
  private weight;
  private volume;
  private static _volumeFields: string[] = [
    'loadoutVolume',
    'overrideVolume',
  ];

  private static _cp1Fields: string[] = [
    'concentration',
    'lotNo',
    'sapMaterialNumber',
    'totalCOGS',
    'overrideMixingProcedure',
    'displayName'
  ];

  private static _cp2Fields: string[] = [
    'concentration',
    'lotNo',
    ...StageFluidMaterialComponent._volumeFields,
    'dryVolume',
    'overrideMixingProcedure',
    'displayName'
  ];

  private static _cp4Fields: string[] = [
    ...StageFluidMaterialComponent._volumeFields,
    'overrideMixingProcedure',
    'actualQty'
  ];

  private static _scheduleEditFields: string[] = [
    ...StageFluidMaterialComponent._volumeFields,
    'overrideMixingProcedure',
    'concentration',
    'lotNo',
    'dryVolume',
    'totalCOGS',
    'displayName'
  ];

  // array index corresponds to cp number,
  // if 0 - this is schedule edit view
  private static _visibleFields: string[][] = [
    StageFluidMaterialComponent._scheduleEditFields,
    StageFluidMaterialComponent._cp1Fields,
    StageFluidMaterialComponent._cp2Fields,
    [],
    StageFluidMaterialComponent._cp4Fields
  ];

  @Input()
  public stageState: StageStateManager;

  @Input()
  public fluidState: FluidStateManager;

  @Input()
  public readonly materials: PumpScheduleStageMaterialModel[];

  @Input()
  public readonly header: string;

  @Input()
  public readonly showMix: boolean;

  @Input()
  public readonly refreshForm: Observable<any>;

  @Input()
  public readonly fluidType: string;

  @Input()
  public readonly hasScope3access: boolean;

  public isTableExpanded: boolean = false;

  public UnitType = UnitType;

  public errorMessages = {
    sapMaterialNumber: [
      new ErrorMessageModel('notExisted', 'SAP Number does not exist. Please input another value.')
    ],
    overrideVolume: [
      new ErrorMessageModel('negativeNumber', ValidatorSetting.ERROR_NEGATIVE),
      new ErrorMessageModel('isNumber', ValidatorSetting.ERROR_NUMBER_NUMBER),
      new ErrorMessageModel('max', ValidatorSetting.ERROR_UNIT_DECIMAL_MAX)
    ]
  };

  private readonly _subscriptions = new Subscription();

  private _currentFluid: FluidModel = null;

  private stageStateFluids = [];

  public constructor(
    private fluidService: FluidService,
    private readonly _editJobAdapter: EditJobAdapter,
    private cd: ChangeDetectorRef,
    private unitConversionService: UnitConversionService,
    private userSettingService: UserSettingService,
    private readonly _scheduleStateFactory: PumpScheduleStateFactory,
  ) {
  }

  public get isBlend(): boolean {
    return this.fluidType == MATERIAL_TYPE.BLEND && this.stageState._model.slurry.blendName != null;
  }
  private get _viewState(): ViewState {

    return this.fluidState.viewState;
  }

  public get isOffshoreJob$(): Observable<boolean> {

    return this._viewState.isOffshoreJob$;
  }

  public get isExistingJob(): boolean {

    return this._viewState.isExistingJob;
  }

  public get isOverrideVolumeEditable(): boolean {

    return !this._viewState.isCP4View;
  }

  public get isSapMaterialEditable(): boolean {

    return !this._viewState.isCP4View;
  }

  public get isMixEditable(): boolean {

    return !(this._viewState.isCP4View || this._viewState.isCP2Completed) && this._viewState.isJobEditable;
  }

  public get enablingCPStates$(): Observable<boolean> {

    return this._viewState.isCP1Submitted$
      .pipe(
        map(isCP1Submitted => {

          return !this._viewState.isJobCompleted
            && (isCP1Submitted || this._viewState.isCP2Approved)
            && !this._viewState.isCP2Completed
            && !this._viewState.isCP4Completed;
        }),
        shareReplay()
      );
  }

  public get disablingJobStates(): boolean {

    return this._viewState.isJobCancelled || this._viewState.isJobClosed;
  }

  public get isCP4QtyDisabled(): boolean {
    return this._viewState.controlPointNumber === 4;
  }

  public get disablingCPStates(): boolean {

    return this.disablingJobStates || this._viewState.isCP2Completed;
  }
  public get totalBlendCo2e(): string {

    return this.fluidState.totalBlendCo2e;
  }

  public get totalBlendActualCo2e(): string {

    return this.fluidState.totalBlendActualCO2e;
  }
  public get classOnCPState$(): Observable<string> {

    return this.enablingCPStates$
      .pipe(
        map(enablingCPStates => {

          if (this.disablingCPStates) {

            return 'custom-disable';

          } else if (enablingCPStates) {

            return 'exception-disable-item';
          }

          return '';

        }),
        shareReplay()
      );
  }

  public get classOnCP2State$(): Observable<string> {

    return this.enablingCPStates$
      .pipe(
        map(enablingCPStates => {

          if (this.disablingCPStates || this._viewState.isCP2Prepared) {

            return 'custom-disable';

          } else if (enablingCPStates) {

            return 'exception-disable-item';
          }

          return '';

        }),
        shareReplay()
      );
  }
  public ngOnInit(): void {
    this.weight = this.unitConversionService.getCurrentUnitMeasure(UnitType.Weight);
    this.volume = this.unitConversionService.getCurrentUnitMeasure(UnitType.LargeVolume);
    this._subscriptions.add(
      this.fluidState.model$
        .pipe(
          switchMap(fluid => {

            this._currentFluid = fluid;

            return this._editJobAdapter.updateSAPMaterial$
              .pipe(
                take(1),
                map(updateData => {
                  return {
                    fluid: fluid,
                    sapUpdateData: updateData
                  };
                })
              );
          })
        )
        .subscribe(({ fluid, sapUpdateData }) => {

          this._updateMappingMaterial(fluid, sapUpdateData)
        })
    );
    this._subscriptions.add(
      this.stageState.availableFluids$.subscribe(fluids => {
        this.stageStateFluids = fluids;
      })
    );
    this._subscriptions.add(this.fluidService.materialMappingUpdated$.subscribe(_ => this.cd.markForCheck()));
    this._subscriptions.add(this.fluidService.updateMaterial$.subscribe(
      (values: UpdateMaterialParameters) => {
        const updMaterial = values.material;
        if (!updMaterial || (updMaterial.fluidMaterialId == null && updMaterial.fluidMaterialName == null))
          return;

        for (let m of this.materials) {
          if ((updMaterial.fluidMaterialId != null && m.fluidMaterialId == updMaterial.fluidMaterialId)
            || (updMaterial.fluidMaterialName != null && m.fluidMaterialName == updMaterial.fluidMaterialName)) {
            m.sapMaterialNumber = updMaterial.sapMaterialNumber;
            m.sapMaterialName = updMaterial.sapMaterialName;
            m.sapMaterialDisplayName = updMaterial.sapMaterialDisplayName;
            this.getSapMaterialNumberControl(updMaterial).setValue(updMaterial.sapMaterialNumber);
            this.getSapMaterialNameControl(updMaterial).setValue(updMaterial.sapMaterialName);
            this._updateFluidsTabSapMaterial(updMaterial, values.sapMaterial)
          }
        }
        this.cd.markForCheck();
      }
    ));


    if (this.refreshForm != null) {
      this.refreshForm.subscribe(() => {
        this.cd.detectChanges();
      })
    }
  }

  public ngOnDestroy(): void {

    this._subscriptions.unsubscribe();
  }

  public isVisible$(field: string): Observable<boolean> {

    let mixFieldVisible = true;
    if (field === 'overrideMixingProcedure') {

      mixFieldVisible = this.showMix;
    }

    return this.fluidState.viewState.isFieldVisible$(
      field,
      StageFluidMaterialComponent._visibleFields
    )
      .pipe(
        map(visible => {

          return visible && mixFieldVisible;
        })
      );
  }

  public getDropdownMixItems$(material: PumpScheduleStageMaterialModel)
    : Observable<SelectItem<string>[]> {

    return this._getMaterialState(material).dropdownMixItems$;
  }

  public toggleExpanded(): void {

    this.isTableExpanded = !this.isTableExpanded;
  }

  public getForm(material: PumpScheduleStageMaterialModel): UntypedFormGroup {

    return this._getMaterialState(material).form;
  }

  public getIFactsMaterialIdControl(material: PumpScheduleStageMaterialModel): AbstractControl {

    return this.getForm(material).controls.ifactMaterialId;
  }

  public getSapMaterialNumberControl(material: PumpScheduleStageMaterialModel): AbstractControl {

    return this.getForm(material).controls.sapMaterialNumber;
  }

  public getSapMaterialNameControl(material: PumpScheduleStageMaterialModel): AbstractControl {

    return this.getForm(material).controls.sapMaterialName;
  }

  public isMixWater(material: PumpScheduleStageMaterialModel): boolean {

    return this._getMaterialState(material).isMixWater;
  }

  public isDry(material: PumpScheduleStageMaterialModel): boolean {

    return this._getMaterialState(material).isDry;
  }

  public isLiquidUnit = (material: PumpScheduleStageMaterialModel): boolean => this._getMaterialState(material).isLiquidUnit;

  public cogsUomIncompatible$(material: PumpScheduleStageMaterialModel): Observable<boolean> {

    return this._getMaterialState(material).cogsUomIncompatible$;
  }

  public cogsAvailable$(material: PumpScheduleStageMaterialModel): Observable<boolean> {

    return this._getMaterialState(material).cogsAvailable$;
  }

  public getCogs$(material: PumpScheduleStageMaterialModel): Observable<number> {

    return this._getMaterialState(material).cogs$
      .pipe(
        map(cost => {
          return cost.totalCOGS;
        }),
        shareReplay()
      );
  }

  public bulkDensityAvailable$(material: PumpScheduleStageMaterialModel): Observable<boolean> {

    return this._getMaterialState(material).bulkDensityAvailable$;
  }

  public updateSapMaterial(material: PumpScheduleStageMaterialModel, sapMaterial: ISAPMaterialModel): void {
    this._getMaterialState(material).updateSap(sapMaterial);
  }

  public onUpdateSapMaterial(material: PumpScheduleStageMaterialModel, sapMaterial: ISAPMaterialModel): void {
    this._getMaterialState(material).updateSapManually(sapMaterial, material);
    this.fluidService.updateMaterial$.next({ material, sapMaterial });
    this.updateFluidInAllStages();
  }

  private updateFluidInAllStages() {
    this.fluidState.model$.subscribe(model =>
      this._scheduleStateFactory.updateFluidInAllStages(model.requestId, model.slurryNo, model)
    );
  }

  public isCPviewed(checkCP: ControlPointType = null): boolean {

    const currentCP = this.fluidState.viewState.currentCPinView;

    if (!checkCP)
      return !!checkCP === !!currentCP;

    return checkCP === currentCP;
  }

  private _getMaterialState(material: PumpScheduleStageMaterialModel): FluidMaterialStateManager {

    return this.fluidState.getMaterialState(material);
  }

  private _updateFluidsTabSapMaterial(material: PumpScheduleStageMaterialModel, sapMaterial: ISAPMaterialModel): void {

    const slurries = this._editJobAdapter.fluidFormArray.controls;

    if (!slurries || !this._currentFluid || !this.stageStateFluids.length) { return; }

    slurries.forEach((slurry: UntypedFormGroup, index: number) => {

      let stageStateFluid = this.stageStateFluids[index];

      if (!!slurry.value?.requestId) {
        let stageStateFluidLookup = this.stageStateFluids.find(x => x.requestId == slurry.value.requestId);
        if (!!stageStateFluidLookup) {
          stageStateFluid = stageStateFluidLookup;
        }
      }

      const isSuppelemtalsGroup = this.materials.every(m => this._getMaterialState(m).isSupplemental);
      const isBlendGroup = this.materials.every(m => this._getMaterialState(m).isBlend);
      let fluidMaterials;
      let fluidMaterialForm;

      if (isSuppelemtalsGroup) {
        fluidMaterials = stageStateFluid.supplementalMaterial;
        fluidMaterialForm = slurry.controls.supplementalMaterial as UntypedFormArray;
      } else if (isBlendGroup) {
        fluidMaterials = stageStateFluid.fluidBlendMaterial;
        fluidMaterialForm = slurry.controls.fluidBlendMaterial as UntypedFormArray;
      } else if (!isBlendGroup) {
        fluidMaterials = stageStateFluid.fluidAdditiveMaterial;
        fluidMaterialForm = slurry.controls.fluidAdditiveMaterial as UntypedFormArray;
      }
      this._setFluidMaterial(material, sapMaterial, fluidMaterialForm, fluidMaterials, this._currentFluid.requestDisplay);
    });
  }

  private _setFluidMaterial(
    material: PumpScheduleStageMaterialModel,
    sapMaterial: ISAPMaterialModel,
    materialFormArray: UntypedFormArray,
    fluidMaterials,
    requestDisplay: string
  ) {

    if (!fluidMaterials || !materialFormArray || !sapMaterial) { return; }

    fluidMaterials.forEach((fluidMaterial, index) => {

      if (fluidMaterial.materialId !== material.ifactMaterialId) { return; }

      const fluidMaterialForm = materialFormArray.controls[index] as UntypedFormGroup;

      if (fluidMaterialForm?.controls?.sapMaterialName) {

        if (fluidMaterial.id === material.fluidMaterialId || !fluidMaterialForm.controls.sapMaterialName.value) {

          fluidMaterialForm.controls.sapMaterialNumber.setValue(sapMaterial.materialNumber);
          fluidMaterialForm.controls.sapMaterialName.setValue(sapMaterial.materialName);
          fluidMaterialForm.controls.materialNumberAssignedFlag.setValue(MaterialNumberAssignedFlag.Manual);
          fluidMaterialForm.controls.sapMaterialNumberAssignedValue.setValue(requestDisplay);
        }
      }
    });
  }

  // !!!TODO: These methods below are copied from legacy code. Should be refactored.
  private _updateMappingMaterial(fluid: FluidModel, sapUpdateData: { isBlend: boolean }): void {

    if (fluid && (fluid.id || fluid.slurryIdHDF || fluid.slurryId)) {
      const { id, slurryIdHDF, slurryId, slurryNo } = fluid;
      const slurry = this._editJobAdapter.fluidFormArray.controls
        .find((x: UntypedFormGroup) => (id && x.controls.id.value === id) || (slurryIdHDF && x.controls.slurryIdHDF.value === slurryIdHDF) ||
          (slurryId && x.controls.slurryId.value === slurryId && x.controls.slurryNo.value === slurryNo && slurryNo != null)) as UntypedFormGroup;

      if (slurry) {

        const fluidBlendMaterialFm = slurry.controls.fluidBlendMaterial as UntypedFormArray;
        const fluidAdditiveMaterialFm = slurry.controls.fluidAdditiveMaterial as UntypedFormArray;
        const fluidSupplementalMatFm = slurry.controls.supplementalMaterial as UntypedFormArray;

        const isBlendGroup = this.materials.every(m => this._getMaterialState(m).isBlend);
        const isSuppelemtalsGroup = this.materials.every(m => this._getMaterialState(m).isSupplemental);

        if (isSuppelemtalsGroup && fluidSupplementalMatFm.controls.length === this.materials.length) {
          for (let index = 0; index < this.materials.length; index++) {
            this._setStageMaterial(fluidSupplementalMatFm, index);
          }
        } else {
          if ((sapUpdateData.isBlend == null || sapUpdateData.isBlend && isBlendGroup) && fluidBlendMaterialFm.controls.length === this.materials.length) {
            for (let index = 0; index < this.materials.length; index++) {
              this._setStageMaterial(fluidBlendMaterialFm, index);
            }
          }
          if ((sapUpdateData.isBlend == null || !isBlendGroup) && fluidAdditiveMaterialFm.controls.length === this.materials.length) {
            for (let index = 0; index < this.materials.length; index++) {
              this._setStageMaterial(fluidAdditiveMaterialFm, index);
            }
          }
        }
      }
    }
  }

  private _setStageMaterial(fluidMaterialFormsArray, index) {
    const fluidMaterialForm = fluidMaterialFormsArray.controls[index] as UntypedFormGroup;

    const material = this.materials[index];
    const stageMaterialForm = this.getForm(this.materials[index]);
    if (fluidMaterialForm && fluidMaterialForm.controls && fluidMaterialForm.controls.sapMaterialName) {
      stageMaterialForm.controls.sapMaterialNumber.setValue(fluidMaterialForm.controls.sapMaterialNumber.value);
      stageMaterialForm.controls.sapMaterialNumber.setErrors(fluidMaterialForm.controls.sapMaterialNumber.errors);

      this.updateSapMaterial(material, {
        materialNumber: stageMaterialForm.controls.sapMaterialNumber.value,
        materialName: fluidMaterialForm.controls.sapMaterialName.value
      });
    }

    if (fluidMaterialForm && fluidMaterialForm.controls && fluidMaterialForm.controls.materialId && fluidMaterialForm.controls.materialName) {
      stageMaterialForm.controls.ifactMaterialId.setValue(fluidMaterialForm.controls.materialId.value);
      stageMaterialForm.controls.ifactMaterialName.setValue(fluidMaterialForm.controls.materialName.value);
    }
  }

  isPlannedScope3Co2eNotNull(material) {
    return this.getForm(material).controls.plannedScope3Co2e.value !== null;
  }

  isActualScope3Co2eNotNull(material) {
    return this.getForm(material).controls.actualScope3Co2e.value !== null;
  }
  getPlannedScope3(material) {
    return this.getForm(material).controls.plannedScope3Co2e.value == 0 ? '0' : '';
  }
  getActualScope3(material) {
    return this.getForm(material).controls.actualScope3Co2e.value == 0 ? '0' : '';
  }

  transformResult(material) {
    const value: any = material.plannedScope3Co2e;
    return this.unitConversionService.ConvertEmissionsToUserUnit(value, this.weight, this.volume);
  }
}

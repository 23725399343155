
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { StageStateManager } from '../../state/stage-state-manager';
import { IFactTestName, TestTableName } from './stage-test-results.constant';
import { PumpScheduleStageTestTable } from 'libs/models/ifact/ifacts-request-tests';
import { Observable, Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { UpdateStageTests } from 'apps/vida/src/modules/pump/components/stage-test-results/test-results-mapper';
import { ChangeValueExport } from './pump-schedule-test-result-base.component';
import { IFactsAvailableSlurry } from 'libs/models/ifact/ifacts-available-slurry';
import { PumpScheduleStageTabs } from '../../../shared/constant/pump-schedule-stage-tabs';

@Component({
    selector: 'stage-test-results',
    templateUrl: './stage-test-results.component.html',
    styleUrls: ['./stage-test-results.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StageTestResultsComponent implements OnInit, OnDestroy {
    @Input() stageState: StageStateManager;
    @Input() isJobEditView: boolean = false;

    @Output() onStageAlign = new EventEmitter<any>();

    subscription: Subscription = new Subscription();

    get canEdit(): boolean {
        return this.stageState.viewState.isJobEditable;
    }

    get testTables$(): Observable<PumpScheduleStageTestTable[]> {
        return this.stageState.testTables$;
    }

    get availableSlurries(): IFactsAvailableSlurry[] {
        return this.stageState?._model?.slurry?.requestInfo?.availableSlurries;
    }

    ngOnInit() {
    }

    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }

    changeSlurryNo(value: ChangeValueExport) {
        this.onStageAlign.emit({ index: PumpScheduleStageTabs.TestResults });
        this.stageState._model.pumpScheduleStageTestTables = value.testTables;
        this.stageState.updateTestTables(UpdateStageTests(this.stageState._model, this.stageState._model.slurry)?.pumpScheduleStageTestTables || []);
    }

    deleteTest(value: ChangeValueExport) {
        this.stageState._model.pumpScheduleStageTestTables = value.testTables;
        this.stageState.updateTestTables(value.testTables);
    }
}

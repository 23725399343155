<div>
    <div class="my-2"><b>Data to be updated</b></div>
    <div class="container h-100 w-100 pr-4">
        <div class="row my-2">
            <div class="col-sm-2">
            </div>
            <div class="col-sm-4">
                <h5>Existing Data</h5>
            </div>
            <div class="col-sm-5 pad-second-col">
                <h5>New Data</h5>
            </div>
        </div>

        <div class="row p-0 grey border">
            <div class="col-sm-2 header align-self-center text-right">
                Customer:
            </div>
            <div class="col-sm-4 pad align-self-center">
                {{existingData?.customerName}}
            </div>
            <div class="col-sm-5 pad pad-second-col align-self-center">
                {{newData?.customerName}}
            </div>
        </div>
        <div class="row p-0 border">
            <div class="col-sm-2 header align-self-center text-right">
                Well Name:
            </div>
            <div class="col-sm-4 pad align-self-center">
                {{existingData?.wellName}}
            </div>
            <div class="col-sm-5 pad pad-second-col align-self-center">
                <div >{{newData?.wellName}}</div>
                <div class="row h-100">
                    <div class="col-sm-1 text-danger pr-2">
                        <i *ngIf="!isExistedWellName" class="fa fa-exclamation-triangle"></i>
                    </div>
                    <div class="col-sm-11 p-0 text-danger">
                        <div *ngIf="!isExistedWellName" class="col-sm-12 p-0">
                            Well does not exist in the Well Management master list.
                        </div>
                        <div *ngIf="!isExistedWellName" class="col-sm-12 p-0">Well name will not be updated.</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row p-0 grey border">
            <div class="col-sm-2 header align-self-center text-right">
                Well Number:
            </div>
            <div class="col-sm-4 pad">
                {{existingData?.wellNumber}}
            </div>
            <div class="col-sm-5 pad-second-col pad">
                <div>{{newData?.wellNumber}}</div>
                <div *ngIf="!newData?.wellNumber" class="text-danger"><i class="fa fa-exclamation-triangle pr-2"></i>No
                    data found. Existing data will not be overwritten.</div>
            </div>
        </div>
        <div class="row p-0 border">
            <div class="col-sm-2 header text-right">
                BOM:
            </div>
            <div class="col-sm-4 pad">
                {{existingData?.bomTypeDescription}}
            </div>
            <div *ngIf="newData == null || newData?.isBOMTypeRequired" class="col-sm-5 pad-second-col pad">
                {{newData?.bomTypeDescription}}
                <div *ngIf="newData?.bomTypeDescription === null" class="text-danger"><i class="fa fa-exclamation-triangle pr-2"></i>
                    Invalid BOM Type. Existing data will not be overwritten.
                </div>
            </div>
        </div>
        <div class="row p-0 grey border">
            <div class="col-sm-2 header text-right">
                API:
            </div>
            <div class="col-sm-4 pad">
                {{existingData?.wellApiUwi}}
            </div>
            <div class="col-sm-5 pad-second-col pad">
                <div>{{newData?.wellApiUwi}}</div>
                <div *ngIf="!newData?.wellApiUwi" class="text-danger"><i class="fa fa-exclamation-triangle pr-2"></i>No
                    data found. Existing data will not be overwritten.</div>
            </div>
        </div>
        <div class="row p-0 border">
            <div class="col-sm-2 header text-right">
                Ship To:
            </div>
            <div class="col-sm-4 pad">
               {{existingData?.shipTo}}  {{existingData?.shipToLocation}}
            </div>
            <div class="col-sm-5 pad-second-col pad">
                {{newData?.shipTo}}   {{newData?.shipToLocation}}
            </div>
        </div>
        <div class="row p-0 grey border">
            <div class="col-sm-2 header text-right">
                Halliburton Account Representative:
            </div>
            <div class="col-sm-4 pad">
               {{existingData?.accountRepresentative?.fullName}}
            </div>
            <div class="col-sm-5 pad-second-col pad">
                {{newData?.accountRepresentative?.fullName}}
            </div>
        </div>
        <div class="row p-0 border">
            <div class="col-sm-2 header text-right">
                Account Rep Phone Number:
            </div>
            <div class="col-sm-4 pad">
               {{existingData?.accountRepresentative?.phoneNumber}}
            </div>
            <div class="col-sm-5 pad-second-col pad">
                {{newData?.accountRepresentative?.phoneNumber}}
            </div>
        </div>
        <div class="row">
            <div class="col-sm-2"></div>
            <div class="col-sm-4 text-right">
                <a class="so-cancel-btn" (click)="onClose()">Cancel</a>
            </div>
            <div class="col-sm-5">
                <button class="btn btn-primary so-btn keep-ex-data" (click)="onSubmit()">UPDATE TO NEW DATA</button>
            </div>
        </div>
    </div>
</div>

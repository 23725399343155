import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { MODEL_CONTANTS, PrimaryStatus, SpacerMixMethod } from 'libs/constants';
import { MODAL_ID } from 'libs/constants/modal-id.constants';
import { environment } from 'libs/environment';
import { FluidModel, IPumpScheduleFluidType, Job, PumpSchedule, PumpScheduleStageModel } from 'libs/models';
import { PumpScheduleCOGSModel, PumpScheduleStageCOGSModel } from 'libs/models/entities/pump-schedule-stage-cogs.model';
import { ApplicationStateService, FluidService, JobService } from 'libs/shared/services';
import { ConfirmDialogService, DynamicSidebarService } from 'libs/ui';
import { BsModalService } from 'ngx-bootstrap/modal';
import { MessageService, SelectItem } from 'primeng/api';
import { BehaviorSubject, combineLatest, concat, from, merge, Observable, Observer, of, Subject, Subscription } from 'rxjs';
import { debounceTime, filter, map, mergeAll, shareReplay, switchMap, switchMapTo, take, tap } from 'rxjs/operators';
import { EditJobAdapter } from '../../edit-job/adapters';
import { PumpScheduleService } from '../../pump-schedule/services';
import { NoMaterialDataComponent } from '../../shared/components/no-material-data-modal/no-material-data-modal.component';
import { CommonMessageText } from '../../shared/constant';
import { MissingDataMaterial } from '../../shared/models/no-cogs-material.model';
import { IfactFluidSettingsSidebarComponent } from '../../sidebar-dialogs/components';
import { PumpScheduleFormManager } from '../form-manager';
import { PumpScheduleStageStateManager } from '../models/pump-schedule-stage-state-manager.model';
import { ViewState } from '../view-state';
import { StageStateManager } from './stage-state-manager';
import { PumpScheduleStateFactory as StateFactory } from './state-factory';
import { TestInfo } from 'libs/models/ifact/ifacts-request-tests';

interface IStageListUpdate {
  schedule: PumpSchedule;
  stagesStates: StageStateManager[];
  stageIndex: number;
  confirmed?: boolean;
}

interface IMissingDataMaterialsList {
  materialsByStages: MissingDataMaterial[][];
  type: string;
  stageIndex?: number;
}

export class PumpScheduleStateManager {

  private _subscriptions = new Subscription();

  public _stagesStatesSrc = new BehaviorSubject<StageStateManager[]>([]);

  public update70BC = new BehaviorSubject<string>(null);

  private _updateStageRequestSrc = new Subject<number>();

  private _insertStageRequestSrc = new Subject<number>();

  private _deleteStageRequestSrc = new Subject<number>();

  private _cogsHelpRequestSrc = new Subject<number>();

  private _missingBulkDensityHelpRequestSrc = new Subject<number>();

  private _updateStageRequest$ = this._updateStageRequestSrc.asObservable();

  private _insertStageRequest$ = this._insertStageRequestSrc.asObservable();

  private _deleteStageRequest$ = this._deleteStageRequestSrc.asObservable();

  private _cogsHelpRequest$ = this._cogsHelpRequestSrc.asObservable();

  private _missingBulkDensityHelpRequest$ = this._missingBulkDensityHelpRequestSrc.asObservable();

  private _cogsSaved: boolean = false;

  public readonly updateFluidNames$: Subject<object> = new Subject<object>();

  public readonly form: UntypedFormGroup;

  public readonly model$: Observable<PumpSchedule> =
    combineLatest([
      this._pumpSchedule$,
      this._stageTypes$
    ])
      .pipe(
        map(([schedule, stageTypes]) => {
          return this._init(schedule, stageTypes);
        }),
        shareReplay()
      );

  public readonly fluids$: Observable<FluidModel[]> = this._fluidsSrc?.asObservable()
    .pipe(
      map(fluids => {

        return this._buildFluidList(fluids);
      }),
      shareReplay()
  );
  public readonly stagesStates$: Observable<StageStateManager[]> = this._stagesStatesSrc
    .asObservable().pipe(shareReplay());

  public readonly cementStagesStates$: Observable<StageStateManager[]> = this.stagesStates$
    .pipe(
      switchMap(stagesStates =>
        combineLatest(stagesStates.map(s => s.type$))
          .pipe(
            map(_ => {

              return stagesStates.filter(s => s.isCement);
            })

          )
      ),
      shareReplay()
    );

  public readonly totalBlendCo2e$: Observable<number> = this.model$
    .pipe( 
      switchMap(model => { 
        return of(model.stages.sum(x => x.totalBlendCO2e));
      }),
      shareReplay()
  );

  public readonly totalBlendActualCo2e$: Observable<number> = this.model$ 
    .pipe(
      switchMap(model => {
        return of(model.stages.sum(x => x.totalBlendActualCO2e));
      }),
      shareReplay()
    );

  public cementStagesStatesWithoutShutDownSubject$: BehaviorSubject<StageStateManager[]> = new BehaviorSubject([])

  public readonly cementStagesStatesWithoutShutDown$: Observable<StageStateManager[]> = this.stagesStates$
    .pipe(
      switchMap(stagesStates =>
        combineLatest(stagesStates.map(s => s.type$))
          .pipe(
            map(_ => {

              return stagesStates.filter(s => s.isCement && !s.isShutdownStage);
            })

          )
      ),
      shareReplay()
    );

  private readonly _selectedStagesTypes$: Observable<IPumpScheduleFluidType[]> = this.stagesStates$
    .pipe(
      switchMap(stagesStates =>

        combineLatest(stagesStates.map(s => s.type$))
      ),
      shareReplay()
    );

  public readonly stageNumbers$: Observable<number[]> = this._selectedStagesTypes$
    .pipe(
      map(selectedTypes => {

        return this._renumberStages(selectedTypes);
      }),
      shareReplay()
    );

  public readonly dropdownFluidItems$: Observable<SelectItem[]> = this.fluids$?.pipe(
    map(fluids => {

      return fluids.map(fluid => {

        const item: SelectItem<string> = {
          value: fluid.id,
          label: fluid.displayName
        };

        return item;
      });
    }),
    shareReplay()
  );

  public readonly cogs$: Observable<PumpScheduleCOGSModel> =
    combineLatest([
      this.model$,
      this.stagesStates$
        .pipe(
          switchMap(stagesStates =>
            combineLatest(stagesStates.map(s => s.cogs$))
          )
        ),
      this.viewState.isCogsCalculationDisabled$
    ])
      .pipe(
        map(([model, fluidsCosts, calcDisabled]) => {

          return this._calculateTotalCost(model, fluidsCosts, calcDisabled);
        }),
        shareReplay()
      );

  public readonly dryWeight$: Observable<number> = this.stagesStates$
    .pipe(
      switchMap(stagesStates =>
        combineLatest(stagesStates.map(s => s.dryWeight$))
      ),
      map(fluidsWeights => {

        return fluidsWeights.sum();
      }),
      shareReplay()
    );

  public readonly dryVolume$: Observable<number> = this.stagesStates$
    .pipe(
      switchMap(stagesStates =>
        combineLatest(stagesStates.map(s => s.dryVolume$))
      ),
      map(fluidsVolumes => {

        return fluidsVolumes.sum();
      }),
      shareReplay()
    );

  public readonly plannedScope3Co2e$: Observable<number> = this.stagesStates$
    .pipe(
      switchMap(stagesStates =>
        combineLatest(stagesStates.map(s => s.plannedScope3Co2e$))
      ),
      map(scope3s => {

        return scope3s.sum();
      }),
      shareReplay()
    );

  public readonly actualScope3Co2e$: Observable<number> = this.stagesStates$
    .pipe(
      switchMap(stagesStates =>
        combineLatest(stagesStates.map(s => s.actualScope3Co2e$))
      ),
      map(scope3s => {

        return scope3s.sum();
      }),
      shareReplay()
    );

  public readonly anyMaterialMissingBulkDensity$: Observable<boolean> = this.stagesStates$
    .pipe(
      switchMap(stagesStates =>
        combineLatest(stagesStates.map(s => s.isFluid$))
          .pipe(
            switchMap(fluidsMap => {

              return combineLatest(
                stagesStates
                  .filter((_, i) => fluidsMap[i])
                  .map(s => s.anyMaterialMissingBulkDensity$)
              );
            })
          )
      ),
      map(missingMap => {

        return missingMap.some(missing => missing);
      }),
      shareReplay()
    );

  public readonly spacerMixMethod$: Observable<SpacerMixMethod> = this.model$
    .pipe(
      switchMap(schedule => {

        return merge(
          this._applicationStateService.isLandChanged
            .pipe(
              map(isLand => {

                return isLand ? SpacerMixMethod.MixOnTheFly : SpacerMixMethod.BatchMix;
              })
            ),
          concat(
            of(schedule.spacerMixMethod),
            this.form.controls.spacerMixMethod.valueChanges
          )
        );
      }),
      tap(smm => {
        this._applicationStateService.spacerMixMethodChange$.next(smm);
      }),
      shareReplay()
    );

  public readonly scheduledShutdown$: Observable<number> = this.model$
    .pipe(
      switchMap(schedule =>
        concat(
          of(schedule.scheduledShutdown),
          this.form.controls.scheduledShutdown.valueChanges
        )
          .pipe(
            map((value: string) => {

              return this.viewState.parseTimeStringToMinutes(value);
            })
          )
      ),
      shareReplay()
    );

  public readonly targetSafetyFactor$: Observable<number> = this.model$
    .pipe(
      switchMap(schedule =>
        concat(
          of(schedule.targetSafetyFactor),
          this.form.controls.targetSafetyFactor.valueChanges
        )
          .pipe(
            map((value: string) => {

              return this.viewState.parseTimeStringToMinutes(value);
            })
          )
      ),
      shareReplay()
    );

  private readonly _updateStage$: Observable<IStageListUpdate> = this.model$
    .pipe(
      switchMap(model => this.stagesStates$
        .pipe(
          switchMap(stagesStates => this._updateStageRequest$
            .pipe(
              map(stageIndex => {

                return {
                  schedule: model,
                  stagesStates: stagesStates,
                  stageIndex: stageIndex
                };
              })
            ))
        )
      ),
      shareReplay()
    );

  private readonly _insertStage$: Observable<IStageListUpdate> = this.model$
    .pipe(
      switchMap(model => this.stagesStates$
        .pipe(
          switchMap(stagesStates => this._insertStageRequest$
            .pipe(
              map(stageIndex => {

                return {
                  schedule: model,
                  stagesStates: stagesStates,
                  stageIndex: stageIndex
                };
              })
            ))
        )
      ),
      shareReplay()
    );

  private readonly _deleteStage$: Observable<IStageListUpdate> = this.model$
    .pipe(
      switchMap(model => this.stagesStates$
        .pipe(
          switchMap(stagesStates => this._deleteStageRequest$
            .pipe(
              switchMap(stageIndex => this._confirmStageDeletion()
                .pipe(
                  map(confirmed => {

                    return {
                      schedule: model,
                      stagesStates: stagesStates,
                      stageIndex: stageIndex,
                      confirmed: confirmed
                    };
                  }),
                  filter(change => {

                    return change.confirmed;
                  })
                )
              ))
          )
        )
      ),
      shareReplay()
    );

  private readonly _cogsHelp$: Observable<IMissingDataMaterialsList> = this.stagesStates$
    .pipe(
      switchMap(stagesStates => combineLatest(stagesStates.map(s => s.noCogsMaterials$))
        .pipe(
          switchMap(noCogsMaterials => this._cogsHelpRequest$
            .pipe(
              map(stageIndex => {

                return {
                  materialsByStages: noCogsMaterials,
                  type: 'COGS',
                  stageIndex: stageIndex
                };
              })
            )
          )
        )
      ),
      shareReplay()
    );

  private readonly _missingBulkDensityHelp$: Observable<IMissingDataMaterialsList> = this.stagesStates$
    .pipe(
      switchMap(stagesStates => combineLatest(stagesStates.map(s => s.noBulkDensityMaterials$))
        .pipe(
          switchMap(noBulkDenistyMaterials => this._missingBulkDensityHelpRequest$
            .pipe(
              map(stageIndex => {

                return {
                  materialsByStages: noBulkDenistyMaterials,
                  type: 'Bulk Density',
                  stageIndex: stageIndex
                };
              })
            )
          )
        )
      ),
      shareReplay()
    );

  jobParams: boolean;

  public constructor(

    private readonly _job: Job,

    private readonly _pumpSchedule$: Observable<PumpSchedule>,

    private readonly _fluidsSrc: BehaviorSubject<FluidModel[]>,

    private readonly _stageTypes$: Observable<IPumpScheduleFluidType[]>,

    private readonly _stageTypeDropdownItems$: Observable<SelectItem<string>[]>,

    private readonly _jobService: JobService,

    private readonly _pumpScheduleService: PumpScheduleService,

    private readonly _fluidService: FluidService,

    private readonly _applicationStateService: ApplicationStateService,

    private readonly _confirmDialogService: ConfirmDialogService,

    private readonly _dynamicSidebarService: DynamicSidebarService,

    private readonly _modalService: BsModalService,

    private readonly _messageService: MessageService,

    private readonly _editJobAdapter: EditJobAdapter,

    private readonly _formManager: PumpScheduleFormManager,

    private readonly _stateFactory: StateFactory,

    private readonly _isFirstPumpDefaultWhenCreate: boolean = false,

    private readonly _scheduleIndex: number,

    public isTestTableChanged$ = new BehaviorSubject(false),
  ) {

    this.form = this._formManager.createScheduleForm(this.viewState.isJobEditable);
    this.jobParams = this._job.isStageJob;
    this._subscribeToChanges();
  }

  public get viewState(): ViewState {

    return this._stateFactory.viewState;
  }

  public destroy(): void {

    this._subscriptions.unsubscribe();

    this._stagesStatesSrc.value.forEach(s => s.destroy());

    this._cogsHelpRequestSrc.complete();
    this._missingBulkDensityHelpRequestSrc.complete();
    this._insertStageRequestSrc.complete();
    this._deleteStageRequestSrc.complete();
    this._stagesStatesSrc.complete();
  }

  public updateStage(index: number): void {

    this._updateStageRequestSrc.next(index);
  }

  public insertStage(index: number): void {

    this._insertStageRequestSrc.next(index);
  }

  public deleteStage(index: number): void {

    this._deleteStageRequestSrc.next(index);
  }

  public linkFluidsActual(
    testTypeId: string,
    slurryTypeId: string,
    requestIdHDF: string,
    stageNumber: number): Observable<Observable<FluidModel>> {

    const linkFluidsSidebar =
      this._dynamicSidebarService.open(IfactFluidSettingsSidebarComponent, {
        data: {
          job: this._job,
          jobId: this._job.id,
          isLinkFluidRequest: true,
          testTypeId: testTypeId,
          slurryTypeId: slurryTypeId,
          iFactsRequestIdHDF: requestIdHDF
        }
      });

    // This strange return value of not flattened observable inside observable
    // is because the client component wants to subscribe just for inner observable
    // to show progress of lengthy fluid linking operation (call to iFacts).
    // A client does not want to subscribe to the outer observable
    // and show progress before the fluid selection dialog (sidebar) closes.

    return linkFluidsSidebar.onClose
      .pipe(
        map((selectedFluids: FluidModel[]) => {
          return this._getFirstLinkedFluid(selectedFluids, true, stageNumber);
        })
      );
  }

  public linkFluids(
    testTypeId: string,
    slurryTypeId: string,
    requestIdHDF: string): Observable<Observable<FluidModel>> {

    const linkFluidsSidebar =
      this._dynamicSidebarService.open(IfactFluidSettingsSidebarComponent, {
        data: {
          job: this._job,
          jobId: this._job.id,
          isLinkFluidRequest: true,
          testTypeId: testTypeId,
          slurryTypeId: slurryTypeId,
          iFactsRequestIdHDF: requestIdHDF
        }
      });

    // This strange return value of not flattened observable inside observable
    // is because the client component wants to subscribe just for inner observable
    // to show progress of lengthy fluid linking operation (call to iFacts).
    // A client does not want to subscribe to the outer observable
    // and show progress before the fluid selection dialog (sidebar) closes.

    return linkFluidsSidebar.onClose
      .pipe(
        map((selectedFluids: FluidModel[]) => {

          return this._getFirstLinkedFluid(selectedFluids, false, undefined);
        })
      );
  }

  public showCogsHelp(stageIndex?: number): void {

    this._cogsHelpRequestSrc.next(stageIndex);
  }

  public showMissingBulkDensityHelp(stageIndex?: number): void {

    this._missingBulkDensityHelpRequestSrc.next(stageIndex);
  }

  private _init(model: PumpSchedule, stageTypes: IPumpScheduleFluidType[]): PumpSchedule {

    this._stagesStatesSrc.value.forEach(s => s.destroy());

    if (!model) {
      model = new PumpSchedule();
    }

    if (!model.stages || !model.stages.length) {

      // init new first stage
      const defaultFirstStage = this._createDeafultFirstStage(stageTypes, true);
      model.stages = [defaultFirstStage];

    } else {

      // reset state index
      this._resetStagesIndexing(model.stages);
      this._formManager.clearScheduleStageFormArray()
    }

    // init form logic
    this._formManager.patchSilent(this.form, model);
    this._setControlsState();

    // update stages
    this._resetStagesStates(model);

    return model;
  }

  private _resetStagesStates(model: PumpSchedule): void { // note: _init

    // _stagesStatesSrc: Observable<StageStateManager[]>
    let stagesStates = this._stagesStatesSrc.value; // get current value

    stagesStates.forEach(s => s.destroy()); // unsubcribe observables each stage

    // map new stages by current model
    stagesStates = model.stages
      .map(stage => {

        const stageState = this._stateFactory.createStageState(stage, this, this._isFirstPumpDefaultWhenCreate, this._scheduleIndex);
        return stageState;
      });

    // update stageStates
    this._stagesStatesSrc.next(stagesStates);
  }

  private _subscribeToChanges(): void {

    this._subscriptions.add(
      this.cementStagesStatesWithoutShutDown$.subscribe(this.cementStagesStatesWithoutShutDownSubject$)
    )
    this._subscriptions.add(this._applicationStateService.jobParams$.subscribe(({ isStageJob }) => {
      this.jobParams = isStageJob
      this._notifyFluidsUsage()
    }))

    this._subscriptions.add(this._applicationStateService.jobTypeChanged.subscribe(jobType => {
      this._job.jobTypeName = jobType.label;
    }))

    this._stateFactory.listPumpScheduleGeneral.map(field => {
      return this._subscriptions.add(
        this.form.get(field)?.statusChanges.subscribe(
          values => {
            this._stateFactory.makePropertiesGeneral();
          }
        )
      )
    })
    this.form.valueChanges.subscribe(
      _ => {
        this._stateFactory.updateCurrentModel()
      }
    )

    this._subscriptions.add(

      combineLatest([
        this._stageTypes$,
        this._stageTypeDropdownItems$,
        this._selectedStagesTypes$
      ])
        .subscribe(([stageTypes, stageTypeDropdownItems, _]) => {

          this._setTopPlugSelectionMode(stageTypes, stageTypeDropdownItems);
        })
    );

    this._subscriptions.add(

      this.stagesStates$
        .pipe(
          switchMap(stagesStates => combineLatest(stagesStates.map(s => s.selectedFluid$))),
          shareReplay()
        )
        .subscribe(_ => {

          this._notifyFluidsUsage();
        })
    );

    this._subscriptions.add(

      this.stagesStates$
        .pipe(
          switchMap(stagesStates => from(stagesStates.map(s => s.placementMethod$))),
          mergeAll()
        )
        .subscribe(stageOrder => {

          this._notifyPlacementMethodChange(stageOrder);
        })
    );

    this._subscriptions.add(
      this._updateStage$.subscribe(upd => {

        this._updateStage(upd);
      })
    );

    this._subscriptions.add(
      this._insertStage$.subscribe(ins => {

        this._insertStage(ins);
      })
    );

    this._subscriptions.add(
      this._deleteStage$.subscribe(del => {

        this._deleteStage(del);
      })
    );

    this._subscriptions.add(

      this._cogsHelp$.subscribe(cogsHelpData => {

        this._showMissingDataHelp(cogsHelpData);
      })
    );

    this._subscriptions.add(

      this._missingBulkDensityHelp$.subscribe(missingBulkDensityData => {

        this._showMissingDataHelp(missingBulkDensityData);
      })
    );

    this._subscriptions.add(

      this.model$
        .pipe(
          switchMap(schedule =>
            this.form.controls.shoeTrackLength.valueChanges
              .pipe(
                map(value => {

                  return {
                    schedule: schedule,
                    shoeTrackLength: value
                  };
                })
              )

          )
        )
        .subscribe(change => {

          change.schedule.shoeTrackLength = change.shoeTrackLength;
        })
    );

    this._subscriptions.add(

      this.model$
        .pipe(
          switchMap(schedule =>
            this.form.controls.shoeTrackVolume.valueChanges
              .pipe(
                map(value => {

                  return {
                    schedule: schedule,
                    shoeTrackVolume: value
                  };
                })
              )

          )
        )
        .subscribe(change => {

          change.schedule.shoeTrackVolume = change.shoeTrackVolume;
        })
    );

    this._subscriptions.add(

      this.model$
        .pipe(
          switchMap(schedule =>
            this.scheduledShutdown$
              .pipe(
                map(value => {

                  return {
                    schedule: schedule,
                    scheduledShutdown: value
                  };
                })
              )

          )
        )
        .subscribe(change => {

          change.schedule.scheduledShutdown =
            this.viewState.formatTime(change.scheduledShutdown);
        })
    );

    this._subscriptions.add(

      this.model$
        .pipe(
          switchMap(schedule =>
            this.targetSafetyFactor$
              .pipe(
                map(value => {

                  return {
                    schedule: schedule,
                    targetSafetyFactor: value
                  };
                })
              )

          )
        )
        .subscribe(change => {

          change.schedule.targetSafetyFactor =
            this.viewState.formatTime(change.targetSafetyFactor);
        })
    );

    this._subscriptions.add(

      this.model$
        .pipe(
          switchMap(schedule =>
            this.form.controls.batchMixingTime.valueChanges
              .pipe(
                map(value => {

                  return {
                    schedule: schedule,
                    batchMixingTime: value
                  };
                })
              )

          )
        )
        .subscribe(change => {

          change.schedule.batchMixingTime =
            this.viewState.formatTime(
              this.viewState.parseTimeStringToMinutes(change.batchMixingTime)
            );
        })
    );

    this._subscriptions.add(

      this.model$
        .pipe(
          switchMap(schedule => this.spacerMixMethod$
            .pipe(
              map(method => {

                return {
                  schedule: schedule,
                  method: method
                };
              })
            )
          )
        )
        .subscribe(change => {

          change.schedule.spacerMixMethod = change.method;
          this._formManager.patchSilent(this.form, { spacerMixMethod: change.method });
        })
    );

    this._subscriptions.add(

      this.model$
        .pipe(
          switchMap(schedule => this.form.controls.spacerMixMethod.valueChanges
            .pipe(
              map(_ => {

                return schedule;
              })
            )
          )
        ).subscribe(schedule => {

          if (this.form.controls.spacerMixMethod.dirty) {

            schedule.isManuallySpacerMixMethod = true;
          }

        })
    );

    this._subscriptions.add(

      this.model$
        .pipe(
          switchMap(schedule => this.form.controls.linerCirculationMethod.valueChanges
            .pipe(
              map(method => {

                return {
                  schedule: schedule,
                  method: method
                };
              })
            )
          )
        ).subscribe(change => {

          if (this.form.controls.linerCirculationMethod.dirty) {

            change.schedule.linerCirculationMethod = change.method;
          }
        })
    );

    this._subscriptions.add(

      this.model$
        .pipe(
          switchMap(schedule => this.form.controls.linerCementLength.valueChanges
            .pipe(
              map(length => {

                return {
                  schedule: schedule,
                  length: length
                };
              })
            )
          )
        ).subscribe(change => {

          if (this.form.controls.linerCementLength.dirty) {

            change.schedule.linerCementLength = change.length;
          }
        })
    );

    this._subscriptions.add(

      this.model$
        .pipe(
          switchMap(schedule => this.form.controls.isVersaFlexLiner.valueChanges
            .pipe(
              map(isVersaFlex => {

                return {
                  schedule: schedule,
                  isVersaFlex: isVersaFlex
                };
              })
            )
          )
        ).subscribe(change => {

          if (this.form.controls.isVersaFlexLiner.dirty) {

            change.schedule.isVersaFlexLiner = change.isVersaFlex;
          }
        })
    );

    this._subscriptions.add(

      this.model$
        .pipe(
          tap(_ => this._resetCogsSavedFlag()),
          switchMap(schedule => this.cogs$
            .pipe(
              map(cogs => {

                return {
                  schedule: schedule,
                  cogs: cogs
                };
              })
            )
          ),
          shareReplay()
        ).subscribe(change => {

          this._saveCogs(change);
        })
    );

    this._subscriptions.add(

      this.updateFluidNames$.subscribe(fluid => {

        this._updateFluidNames(fluid);
        this._editJobAdapter.updateFluidsFromPump(fluid);
      })
    );

    this._subscriptions.add(

      this._editJobAdapter.updateFluidsFromFluidTab$?.pipe(debounceTime(100)).subscribe(fluid => {
        this._updateFluidNames(fluid);
      })
    );

    this._subscriptions.add(

      this.model$
        .pipe(
          switchMap(schedule =>
            this.dryWeight$
              .pipe(
                map(dryWeight => {

                  return {
                    schdule: schedule,
                    dryWeight: dryWeight
                  };
                })
              )
          ),
          shareReplay()
        )
        .subscribe(change => {

          change.schdule.dryWeight = change.dryWeight;
        })
    );

    this._subscriptions.add(

      this.model$
        .pipe(
          switchMap(schedule =>
            this.dryVolume$
              .pipe(
                map(dryVolume => {

                  return {
                    schdule: schedule,
                    dryVolume: dryVolume
                  };
                })
              )
          ),
          shareReplay()
        )
        .subscribe(change => {

          change.schdule.dryVolume = change.dryVolume;
        })
    );
  }

  private _updateStage(upd: IStageListUpdate): void {

    this._formManager.stageListChanged();

    this._stagesStatesSrc.next(upd.stagesStates);
  }

  private _insertStage(ins: IStageListUpdate): void {

    const newStage = this._insertStageModel(ins.schedule, ins.stageIndex);
    const updatedStates = this._insertStageState(ins.stagesStates, newStage, ins.stageIndex);

    // init form logic
    this._formManager.patchSilent(this.form, updatedStates);
    this._formManager.stageListChanged();
    this._stagesStatesSrc.next(updatedStates);
  }

  private _insertStageModel(schedule: PumpSchedule, index: number): PumpScheduleStageModel {
    const currentStages = schedule.stages;

    const stagesBefore = currentStages.slice(0, index);
    const stagesAfter = currentStages.slice(index);

    // user can delete first stage if user add new stage in a pump
    stagesAfter.forEach(s => {
      s.order = s.order + 1;
      s.isRemoveable = true;
    });

    // user can delete first stage if user add new stage in a pump
    if ((stagesAfter.length >= 0) && schedule.order !== 0) {
      stagesBefore.forEach(s => {
        s.isRemoveable = true;
      })
    }

    const newStage = new PumpScheduleStageModel();
    newStage.order = index;
    newStage.isRemoveable = true;

    schedule.stages = [
      ...stagesBefore,
      newStage,
      ...stagesAfter
    ];

    return newStage;
  }

  private _insertStageState(
    stagesStates: StageStateManager[],
    stage: PumpScheduleStageModel,
    index: number): StageStateManager[] {

    const stagesStatesBefore = stagesStates.slice(0, index);
    const stagesStatesAfter = stagesStates.slice(index).map(item => {
      item.setOrder(item.order + 1);
      return item;
    })

    return [
      ...stagesStatesBefore,
      this._stateFactory.createStageState(stage, this, this._isFirstPumpDefaultWhenCreate, this._scheduleIndex),
      ...stagesStatesAfter
    ];
  }

  private _deleteStage(del: IStageListUpdate): void {
    this._deleteStageModel(del.schedule, del.stageIndex);
    const updatedStates = this._deleteStageState(del.stagesStates, del.stageIndex);

    this._formManager.removeStageForm(del.stageIndex);

    // init form logic
    this._formManager.stageListChanged();
    this._formManager.patchSilent(this.form, updatedStates);
    this._stagesStatesSrc.next(updatedStates);
    this._stateFactory.updateCurrentStageRemovable();
  }

  private _deleteStageModel(schedule: PumpSchedule, index: number): void {
    const currentStages = schedule.stages;
    const stagesBefore = currentStages.slice(0, index);

    let stagesAfter: PumpScheduleStageModel[] = [];

    if (index + 1 < currentStages.length) {

      stagesAfter = currentStages.slice(index + 1);

      stagesAfter.forEach(s => {
        s.isRemoveable = true;
        s.order = s.order - 1;
      });
    }

    // user can delete first stage if there are more 1 stage remaining in a pump
    // user can not delete first stage if there are only 1 stage remaining in a pump

    // case 1: user delete the first stage in pump
    if (stagesAfter.length === 1 && stagesBefore.length === 0) {
      stagesAfter.forEach(s => {
        s.isRemoveable = false;
      })
    }

    // case 2: user delete other stages except first stage
    if (stagesAfter.length === 0 && stagesBefore.length === 1) {
      stagesBefore.forEach(s => {
        s.isRemoveable = false;
      })
    }

    schedule.stages = [
      ...stagesBefore,
      ...stagesAfter
    ];
  }

  private _deleteStageState(
    stagesStates: StageStateManager[],
    index: number): StageStateManager[] {

    const stageState = stagesStates[index];
    stageState.destroy();

    const statesBefore = stagesStates.slice(0, index);
    let statesAfter: StageStateManager[] = [];

    if (index + 1 < stagesStates.length) {

      statesAfter = stagesStates.slice(index + 1).map(item => {
        item.setOrder(item.order - 1);
        return item;
      })
    }

    return [
      ...statesBefore,
      ...statesAfter
    ];
  }

  private _confirmStageDeletion(): Observable<boolean> {

    return new Observable((observer: Observer<boolean>) => {

      const accept = () => {

        observer.next(true);
        observer.complete();
      };

      const reject = () => {

        observer.next(false);
        observer.complete();
      };

      this._confirmDialogService.show({
        message: `Are you sure to remove this Stage?`,
        header: 'Confirmation',
        accept: accept,
        reject: reject,
        acceptLabel: 'Yes',
        rejectLabel: 'No'
      });

      return () => {

        this._confirmDialogService.hide();
      };
    });
  }

  private _createDeafultFirstStage(stageTypes: IPumpScheduleFluidType[], isFluidTypeAutoSelect: boolean = false): PumpScheduleStageModel { // note: _init

    const stage = new PumpScheduleStageModel();

    const drillingMudType = stageTypes
      .find(t => t.name === StageStateManager.DrillingMudTypeName);

    if (isFluidTypeAutoSelect) {
      stage.pumpScheduleFluidTypeId = drillingMudType.id;
      stage.pumpScheduleFluidTypeName = drillingMudType.name;
    }

    stage.isRemoveable = false;

    return stage;
  }

  private _resetStagesIndexing(stages: PumpScheduleStageModel[]): void { // note: _init

    // Sometimes stages are saved on server in order with first stage at index 1 (from Control Point?).
    // Sometimes with the first stage index is 0.
    // Here we start from 0.
    stages
      .sort((s1, s2) => s1.order - s2.order)
      .forEach((s, i) => {
        s.isRemoveable = true;
        if (stages.length < 2 && i === 0) {
          s.isRemoveable = false;
        }
        s.order = i;
      });
  }

  private _renumberStages(selectedStagesTypes: IPumpScheduleFluidType[]): number[] {

    const plugStageNumber: number = 0;
    let stageNumber: number = 1;

    return selectedStagesTypes.map(type => {

      return StageStateManager.isPlugType(type) ? plugStageNumber : stageNumber++;
    });
  }

  private _setTopPlugSelectionMode(
    stageTypes: IPumpScheduleFluidType[],
    stageTypeDropdownItems: SelectItem<string>[]
  ): void {

    const foundTopPlugStage = !!this._stagesStatesSrc.value.find(st => st.isTopPlug);

    const topPlugStageType = stageTypes.find(t => t.name === StageStateManager.TopPlugTypeName);
    if (topPlugStageType) {

      this._disableTypeItem(stageTypeDropdownItems, topPlugStageType, foundTopPlugStage);
    }
  }

  private _disableTypeItem(
    stageTypeDropdownItems: SelectItem<string>[],
    type: IPumpScheduleFluidType,
    disable: boolean
  ): void {

    const foundItem = stageTypeDropdownItems.find(i => i.value === type.id);
    if (foundItem) {
      foundItem.disabled = disable;
    }
  }

  public notifyFluidsUsage() {
    this._notifyFluidsUsage()
  }

  private _notifyFluidsUsage(): void {
    const listPump = this._stateFactory.getListModel() || []
    const fluidFormArray = this._stateFactory.getFluidFormArrayData() || []
    const listCurrentStates = listPump.map(
      (item, index) => !!item.stages ? item.stages.map(s => { return { ...s, pumpOrder: index } }) : item.stages
    )
    const listCurrentSlurry = []
    listCurrentStates.map(state => {
      state && state.map(s => {
        if (s && s.slurry && s.slurry.id) {
          const slurry = { ...s.slurry, pumpScheduleNumber: s.pumpOrder + 1 }
          listCurrentSlurry.push(slurry)
        } else {
          const tempId = s && s.slurry && s.slurry.tempId
          const listFluidTempIds = fluidFormArray.map(item => item.tempId);
          const checkIsUsingTempId = tempId && !!listFluidTempIds.filter(item => item === tempId).length
          const checkIsExistsHDFJobId = s && s.slurryIdHDF
          if (checkIsUsingTempId) {
            const slurry = { ...s.slurry, pumpScheduleNumber: s.pumpOrder + 1 }
            listCurrentSlurry.push(slurry)
          } else if (checkIsExistsHDFJobId) {
            const sluryHDF = fluidFormArray.filter(f => f.slurryIdHDF === s.slurryIdHDF)[0]
            const slurry = { ...sluryHDF, pumpScheduleNumber: s.pumpOrder + 1 }
            listCurrentSlurry.push(slurry)
          }
        }
      })
    })

    const usedFluids = listCurrentSlurry
      .filter(s => {
        return s
          && (s.id
            || s.slurryIdHDF
            || s.tempId
            || s.requestId && s.fluid.slurryNo
            || s.displayName
          );
      })

    if (this.jobParams) {
      usedFluids.forEach(fluidModel => {
        const isFluidUseInMultiplePump = usedFluids
          .filter(f =>
            (fluidModel?.id != null && f?.id === fluidModel?.id) ||
            (fluidModel?.tempId != null && f?.tempId === fluidModel?.tempId) ||
            (fluidModel?.slurryIdHDF != null && f?.slurryIdHDF === fluidModel?.slurryIdHDF)
          )
          .filter(f => f.pumpScheduleNumber !== fluidModel.pumpScheduleNumber).length;

        if (isFluidUseInMultiplePump) {
          fluidModel.pumpScheduleNumber = '';
        }
      });
    }
    this._editJobAdapter.isStageJob = this.jobParams
    this._editJobAdapter.updateUsageFluid$(usedFluids);
  }

  private _notifyPlacementMethodChange(stageOrder: number): void {

    const stagesStates = this._stagesStatesSrc.value;

    stagesStates
      .filter(s => s.order > stageOrder)
      .forEach(s => s.placementMethodChanged());
  }

  private _buildFluidList(jobFluids: FluidModel[]): FluidModel[] {

    const stagesStates = this._stagesStatesSrc.value;

    if (jobFluids.length || !stagesStates.length) {

      return jobFluids || [];
    }


    // if no fluids in job yet (arrived from server), but we already have stages with fluids,
    // then select fluids with names from stages until job fluids arrive

    const scheduleFluids = stagesStates
      .filter(s => s.fluid && s.fluid.displayName)
      .map(s => s.fluid);

    const fluidNames = scheduleFluids.map(f => f.displayName);

    // select only unique fluids from stages as several stages may have the same fluid
    return scheduleFluids.filter((f, i) => fluidNames.indexOf(f.displayName) === i);
  }

  private _calculateTotalCost(
    model: PumpSchedule,
    stagesCosts: PumpScheduleStageCOGSModel[],
    calcDisabled: boolean): PumpScheduleCOGSModel {

    const calculatedCost = new PumpScheduleCOGSModel();
    calculatedCost.stages = stagesCosts;

    if (calcDisabled && !this.form.controls.stages.dirty) {

      calculatedCost.totalCOGS = model.totalCOGS;

    } else {

      if (stagesCosts.some(sc => sc.totalCOGS == null)) {
        calculatedCost.totalCOGS = null;
      } else {
        calculatedCost.totalCOGS = stagesCosts.every(sc => sc.totalCOGS || sc.totalCOGS === 0)
          ? stagesCosts.sum(sc => sc.totalCOGS)
          : null;
      }
    }
    return calculatedCost;
  }

  private _resetCogsSavedFlag(): void {

    this._cogsSaved = false;
  }

  private _saveCogs(sc: { schedule: PumpSchedule, cogs: PumpScheduleCOGSModel }): void {

    const { schedule, cogs } = sc;

    const prevCogs = schedule.totalCOGS;

    schedule.totalCOGS = cogs.totalCOGS;

    if (!this._cogsSaved && prevCogs !== schedule.totalCOGS && schedule.pumpScheduleId
      && this._job.controlPoints[0].controlPointState.name == "NonSubmitted") {

      // US 391816 - Save once after pump schedule load in case of price changed
      this._subscriptions.add(

        this._pumpScheduleService.setTotalCogs(schedule.pumpScheduleId, cogs).subscribe()
      );

      this._cogsSaved = true;
    }
  }

  private _showMissingDataHelp(data: IMissingDataMaterialsList): void {

    let missingDataMaterials: MissingDataMaterial[] = [];

    if (data.stageIndex) {

      missingDataMaterials = data.materialsByStages[data.stageIndex]

    } else {

      missingDataMaterials = data.materialsByStages
        .reduce((result, stageNoCogs) => {

          return result.concat(stageNoCogs);
        },
          []
        );
    }

    const materialsGrouped = missingDataMaterials
      .reduce((materialsMap, m) => {

        return materialsMap.set(m.id, [...materialsMap.get(m.id) || [], m]);
      },
        new Map<number, MissingDataMaterial[]>()
      );

    missingDataMaterials = Array.from(
      materialsGrouped,
      ([_, materials]) => {

        const m = materials[0];
        m.appearenceInSlurrySet = materials.length;

        return m;
      });

    const oderedMaterials = missingDataMaterials.sort((p, n) => {
      return p.ifactsMeterilaName.localeCompare(n.ifactsMeterilaName);
    });

    this._modalService.show(NoMaterialDataComponent, {
      initialState: {
        missingDataMaterials: oderedMaterials,
        dataType: data.type,
        currentGroup: this._job.groupName,
        jobGroupId: this._job.group
      },
      id: MODAL_ID.NO_COGS,
      class: 'w-50',
      ignoreBackdropClick: true,
      keyboard: false
    });
  }

  private _getFirstLinkedFluid(selectedFluidsToLink: FluidModel[], isActual: boolean, stageNumber: number): Observable<FluidModel> {
    if (!selectedFluidsToLink || !selectedFluidsToLink.length) {

      return of(null);
    }

    // Remove fluids which are already linked to this job from the list of selected fluids.
    const fluidsToLink: FluidModel[] = this._fluidsDiff(selectedFluidsToLink, this._fluidsSrc.value);

    if (!fluidsToLink.length) {

      // No difference, nothing to link, just return the first fluid (already linked) from initial list
      // to set it for the pump schedule stage.

      const firstFluidToLink = selectedFluidsToLink[0];

      const foundAlreadyLinked = this._fluidsSrc.value
        .find(f => f.requestId === firstFluidToLink.requestId && f.slurryNo === firstFluidToLink.slurryNo);

      if (foundAlreadyLinked.id && !isActual) {
        return of(foundAlreadyLinked);
      }
      else {
        fluidsToLink.push(firstFluidToLink);
      }
    }

    // This call will load fluids data from iFacts before linking.
    let linkedFluids$ = this._fluidService.loadFromIFacts(this._job, ...fluidsToLink);

    if (this._job.id && this._job.id !== MODEL_CONTANTS.EMPTY_GUID) {

      // If linkage is performed for already existing job, then call the data service to make fluid links.
      linkedFluids$ = linkedFluids$
        .pipe(
          switchMap(fluids => {
            for( let fluid of fluids) {
              fluid.stageNumber = stageNumber
            }  

            return this._jobService.linkRequest(this._job.id, fluids, isActual ? 'actual' : undefined);
          })
        );
    }

    return linkedFluids$
      .pipe(
        switchMap(linkedFluids => {
          if (this.viewState.isScheduleEditView) {

            // Full data for the newly linked fluids is loaded from iFacts.
            // This call will load fluids data from iFacts,
            // insert new fluids on Fluids tab of Job Edit view and
            // also will make the next emission of fluids$ observable in editJobAdapter.
            return this._editJobAdapter.loadDataAndInsertFluids(this._job, ...linkedFluids);
          }

          // Have to load fluids data from iFacts second time
          // because _jobService.linkRequest doesn't return enough data for calculation
          // so planned and loadout quantities could be empty
          return this._fluidService.loadFromIFacts(this._job, ...linkedFluids)
            .pipe(
              map(fluids => {
                const newFluidList = [...this._fluidsSrc.value, ...fluids];

                this._fluidsSrc.next(newFluidList);

                return newFluidList;
              })
            );
        }),
        switchMapTo(

          // So, switch now to the new fluid list emission from editJobAdapter, which is relayed by controlPointAdpater.fluids$
          // (see PumpScheduleStateFactory)
          this._fluidsSrc
        ),
        take(1),  // Take only the last one emission, not interested in other fluid list changes here.
        map(freshFluidList => {
          console.log(freshFluidList);
          // Find the first fluid from the original list of selected fluids in the current fresh list of job fluids
          // and return it to the caller.
          const firstFluidToLink = selectedFluidsToLink[0];

          const foundLinked = freshFluidList
            .find(f => f.requestId === firstFluidToLink.requestId && f.slurryNo === firstFluidToLink.slurryNo);

          if (foundLinked) {

            this._messageService.add({
              life: environment.messagePopupLifetimeMs,
              severity: 'success',
              detail: CommonMessageText.FLUID_ADD.SUCCESS
            });
          }

          return foundLinked;
        })
      );
  }

  private _fluidsDiff(seq: FluidModel[], seqToSubtract: FluidModel[]): FluidModel[] {

    return seq
      .filter(sf => {
        return !seqToSubtract.some(f => f.requestId === sf.requestId && f.slurryNo === sf.slurryNo);
      });
  }

  private _setControlsState(): void {

    if (this.viewState.isCP2Prepared || this.viewState.isCP2Completed) {

      this.form.controls.shoeTrackLength.disable(PumpScheduleFormManager.silent);
      this.form.controls.shoeTrackVolume.disable(PumpScheduleFormManager.silent);
    }
  }
  private _updateFluidNames(fluid) {
    const form = this.form.getRawValue();
    const stagesForm = this.form.controls.stages as UntypedFormArray;
    const stagesFormModel = form.stages.map(stage => {
      return this._updateName(stage, fluid['id'], fluid['name']);
    })
    this.updateModelWithName(fluid);
    this.updateFormControlsWithName(stagesForm, stagesFormModel);
  }

  private _updateName(stage, id, name) {
    if (stage.slurry) {
      const stageFluidId = stage.fluidForm.id === null ? stage.slurry.tempId : stage.fluidForm.id;
      if (stageFluidId === id) {
        stage.fluidName = name;
        stage.fluidForm.name = name;
      }
    }
    return stage;
  }

  private updateModelWithName(fluid) {
    this._fluidsSrc.value.forEach(fl => {
      const fluidId = fl.id === null ? fl.tempId : fl.id;
      if (fluidId === fluid.id) {
        fl.name = fluid.name
      }
    })
  }

  private updateFormControlsWithName(stagesForm, stagesFormModel) {
    stagesForm.controls.forEach((form, index) => {
      form.get('fluidName').setValue(stagesFormModel[index].fluidName)
    })
  }


}

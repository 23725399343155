import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, OnChanges } from '@angular/core';
import { ERROR_TYPE } from 'libs/constants';
import { environment } from 'libs/environment';
import { FluidModel, Job, PumpSchedule } from 'libs/models';
import { ApplicationStateService } from 'libs/shared/services';
import { MailService } from 'libs/shared/services/mail.service';
import { ConfirmationDialogService, ConfirmDialogService, ErrorMessageModel } from 'libs/ui';
import { MenuItem, MessageService } from 'primeng/api';
import { concat, Observable, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { JobActionMode } from '../../../shared/constant';
import { PumpScheduleStageStateManager } from '../../models/pump-schedule-stage-state-manager.model';
import { PumpScheduleStateManager } from '../../state/schedule-state-manager';
import { StageStateManager } from '../../state/stage-state-manager';
import { PumpScheduleStateFactory } from '../../state/state-factory';

@Component({
    selector: 'pump-schedule-1-3',
    templateUrl: './schedule.component.html',
    styleUrls: ['./schedule.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScheduleComponent implements OnInit, OnDestroy, OnChanges {

    private _cogs$: Observable<number>;

    @Input()
    public readonly job: Job;    

    @Input()
    public readonly jobActionMode: JobActionMode;

    @Input()
    public readonly refreshForm: Observable<any>;

    @Input() public slurryData: Observable<any>;
    @Input() index: number;

    public usePumpSchedule_1_3: boolean = true;
    public selectedTab: number = 0;
    public isAddPumpScheduleVisible$ = new Observable<boolean>();
    public pumpScheduleOptions: MenuItem[];

    constructor(

        private readonly _scheduleStateFactory: PumpScheduleStateFactory,

        private readonly _mailService: MailService,

        private cd: ChangeDetectorRef,

        private applicationStateService: ApplicationStateService,

        private confirmationDialogService: ConfirmationDialogService,

        private messageService: MessageService,
    ) {}

    public get listPumpScheduleStageStateManager$(): Observable<PumpScheduleStageStateManager[]> {
        return this._scheduleStateFactory.listPumpScheduleStageStateManager$
    }

    public get pumpScheduleStageStateManager(): PumpScheduleStageStateManager[] {
        return this._scheduleStateFactory.listPumpScheduleStageStateManager
    }

    public get pumpScheduleStageStateManagerLength(): number {
        return this.pumpScheduleStageStateManager.length
    }

    public get currentPumpScheduleStageStateManager(): PumpScheduleStageStateManager {
        return this.pumpScheduleStageStateManager[this.selectedTab]
    }

    public get scheduleState(): PumpScheduleStateManager {
        return this.currentPumpScheduleStageStateManager.scheduleState
    }

    public get stagesStates$(): Observable<StageStateManager[]> {
        return this.scheduleState.stagesStates$;
    }

    public get cementStagesStates$(): Observable<StageStateManager[]> {

        return this.scheduleState.cementStagesStates$;
    }

    public get disabledOnControlPointState$(): Observable<boolean> {

        return this.scheduleState.viewState.isCP1Submitted$.pipe(
            map(isCP1Submitted => {

                return isCP1Submitted || this.scheduleState.viewState.isCP1Approved;
            })
        );
    }

    public get enabledDeletePumpScheduleState(): boolean {

        return !this.scheduleState.viewState.isCP2Completed;
    }

    public get enabledAddPumpScheduleState$(): Observable<boolean> {

        return this.scheduleState.viewState.isCP1Submitted$.pipe(
            map(isCP1Submitted => {

                return !(isCP1Submitted || this.scheduleState.viewState.isCP1Approved
                    || this.scheduleState.viewState.isJobCancelled
                    || this.scheduleState.viewState.isJobClosed);
            })
        );
    }

    public get disabledOnJobState(): boolean {

        return this.scheduleState.viewState.isJobCancelled || this.scheduleState.viewState.isJobClosed;
    }

    public get cogs$(): Observable<number> {

        return this.scheduleState.cogs$.pipe(
            map(scheduleCost => {

                return scheduleCost.totalCOGS;
            }),
            shareReplay()
        );
    }


    public ngOnInit() {
        this.initPumpSchedule();
        if (this.refreshForm != null) {
            this.refreshForm.subscribe(() => {
                this.cd.detectChanges();
            });
        }

        this.isAddPumpScheduleVisible$ = concat(
            of(this.job.isStageJob),
            this.applicationStateService.jobParams$
                .pipe(
                    map(c => {
                        const visible = c.isStageJob;
                        this.selectedTab = 0;
                        this.updateCurrentIndex();
                        this._scheduleStateFactory.
                            forEachListPumpScheduleStageStateManager((item) => {
                                if (item.index !== 0) {
                                    item.isHidden = !visible;
                                }
                            }
                            )
                        return visible;
                    }),
                    shareReplay()
                )
        );

    }

    public ngOnChanges() {
        this._scheduleStateFactory.makePropertiesGeneral();
    }

    _autoGenerateNewTabName() {
        const list = this.pumpScheduleStageStateManager
            .map(item => item.displayText)
            .map(item => {
                const arrSplit = item.split(/[^0-9]/gi)
                const lenArr = arrSplit.length

                return parseInt(arrSplit[lenArr - 1])
            })
        const newTabNumber = list.length + 1
        return `Pump Schedule ${newTabNumber < 10 ? '0' : ''}${newTabNumber}`
    }

    _createPumpSchedule() {
        return {
            init: () => {
                this._scheduleStateFactory.loadDataPumpSchedulesByCurrentJob(
                    this.job,
                    this.jobActionMode,
                    null,
                    () => {
                        // Reload the scheduleState to escape data display wrong
                        // after init hidden pump schedule to calculate data.
                        this.onTabChange({index:0})
                    }
                )
            },
            addNew: () => {
                const pumpLength = this.pumpScheduleStageStateManagerLength;
                const pumpName = this._autoGenerateNewTabName()

                const scheduleState = this._scheduleStateFactory.createScheduleState(
                    this.job,
                    null,
                    pumpName,
                    'addNew',
                )
                const model = this._scheduleStateFactory.getModel(false, true)

                const newPump: PumpScheduleStageStateManager = {
                    displayText: pumpName,
                    index: pumpLength,
                    isHidden: false,
                    scheduleState,
                    model,
                }
                this._scheduleStateFactory.pushListPumpScheduleStageStateManager(
                    newPump
                )
            },
            onChanged: (name: string = '') => {
                const pumpName = !!name ? name : this.currentPumpScheduleStageStateManager.displayText
                const scheduleState = this._scheduleStateFactory.createScheduleState(
                    this.job,
                    null,
                    pumpName,
                    'changed',
                    this.currentPumpScheduleStageStateManager,
                    this.selectedTab === 0
                )
                const model = this._scheduleStateFactory.getModel()
                this._scheduleStateFactory.setListPumpScheduleStageStateManagerItem(
                    {
                        displayText: !!name ? name : this.currentPumpScheduleStageStateManager.displayText,
                        index: this.selectedTab,
                        isHidden: false,
                        scheduleState,
                        model,
                    },
                    this.selectedTab
                )
            }
        }

    }

    public updateCurrentIndex() {
        this._scheduleStateFactory.updateCurrentIndex(this.selectedTab);
    }

    public jumpToNewTab() {

        this.selectedTab = this.pumpScheduleStageStateManagerLength - 1;
        this.updateCurrentIndex();
        this._setCurrentScheduleStage();
    }

    public addPumpScheduleBtnOnClicked() {
        this._createPumpSchedule().addNew();
        this._scheduleStateFactory.makePropertiesGeneral('addNew');
        this.jumpToNewTab()
    }

    public initPumpSchedule() {

        this._createPumpSchedule().init()
    }

    public ngOnDestroy(): void {

        this._scheduleStateFactory.destroy();
    }

    public sendMailReport() {

        this._mailService.sendReportSAPMismap();
    }

    _setCurrentScheduleStage() {

        this._createPumpSchedule().onChanged()
    }

    public onTabChange(e) {
        this._scheduleStateFactory.makePropertiesGeneral();
        this.selectedTab = e.index;
        this.updateCurrentIndex();
        this._setCurrentScheduleStage();
    }

    getButtonAddClass() {
        let isAddPumpScheduleVisible;
        this.isAddPumpScheduleVisible$ && this.isAddPumpScheduleVisible$.subscribe(data => isAddPumpScheduleVisible = data)
        return (this.pumpScheduleStageStateManager.length < 12) && isAddPumpScheduleVisible ? 'add-btn-show' : 'add-btn-hidden'
    }

    onDeleteTab(tab) {
        this._scheduleStateFactory.deleteListPumpScheduleStageStateManagerItem(tab.index,
            (updatelist) => {
                const lastedIndex = updatelist.length - 1;
                this.selectedTab = lastedIndex;
                this.updateCurrentIndex();
                this._createPumpSchedule().onChanged(updatelist.displayText);
                this.cd.detectChanges();
            }
        )
    }

    showPumpScheduleOption(tab, pumpOption, event) {

        this.pumpScheduleOptions = [
            {
                label: 'Edit Pump Schedule Name',
                command: () => {
                    this.showPopupChangeName(tab.displayText);
                }
            }
        ];

        if (tab.index != 0) {
            this.pumpScheduleOptions.push(
                {
                    label: 'Delete Pump Schedule',
                    command: () => {
                        this.showPopupDelete(tab);
                    }
                }
            )
        }

        pumpOption.toggle(event);
    }  

    showPopupDelete(tab) {
        if (this.scheduleState.viewState.isCP2Completed) {
            return;
        }

        const acceptFunction = () => { this.onDeleteTab(tab) };
        this.confirmationDialogService.confirm({
            header: 'Delete Confirmation',
            message: `<div>All data will be lost.</div>
            <div>Are you sure you wish to delete <b>${tab.displayText}</b>?</div>`,
            labelConfirm: 'DELETE',
            rejectLabel: 'Cancel',
            accept: acceptFunction,
            reject: () => { },
            type: 'Delete Pump Schedule Name'
        });
    }

    showPopupChangeName(name) {
        if (this.scheduleState.viewState.isCP2Completed || this.scheduleState.viewState.isCP2Prepared) {
            return;
        }

        const acceptFunction = (newName: string) => {
            const currentPump = this.currentPumpScheduleStageStateManager
            const name = `${newName.trim()}`
            if (currentPump.displayText.trim().toLowerCase() === name.toLowerCase()) {
                this.messageService.add({
                    life: environment.messagePopupLifetimeMs,
                    severity: 'success',
                    detail: 'Change name successful'
                })
                return;
            };
            this._scheduleStateFactory.checkNameDuplicate(name, currentPump.model.pumpScheduleId).subscribe(isDuplicated => {
                if (isDuplicated) {
                    this.messageService.add({
                        life: environment.messagePopupLifetimeMs,
                        severity: 'error',
                        detail: 'Failed: Pump Schedules must have unique names'
                    });
                } else {
                    let isDuplicatedWithCurrentPumps = false;
                    const newNameExceptNumber = name;
                    this.pumpScheduleStageStateManager.forEach((item) => {

                        const nameExceptNumber = item.displayText;
                        if (newNameExceptNumber.trim().toLowerCase() === nameExceptNumber.trim().toLowerCase()) {
                            isDuplicatedWithCurrentPumps = true
                        }
                    });

                    if (!isDuplicatedWithCurrentPumps) {
                        this._createPumpSchedule().onChanged(name);
                        this.messageService.add({
                            life: environment.messagePopupLifetimeMs,
                            severity: 'success',
                            detail: 'Change name successful'
                        });
                    }
                    else {
                        this.messageService.add({
                            life: environment.messagePopupLifetimeMs,
                            severity: 'error',
                            detail: 'Failed: Pump Schedules must have unique names'
                        });
                    }
                }
            }, err => {
                this.messageService.add({
                    life: environment.messagePopupLifetimeMs,
                    severity: 'error',
                    detail: 'Failed: Pump Schedules must have unique names'
                })
            })
        }
        this.confirmationDialogService.confirm({
            header: 'Name Pump Schedule',
            message: '',
            labelConfirm: 'SAVE',
            rejectLabel: 'NO',
            comment: '',
            commentFieldTitle: `Would you like to rename ${name}?`,
            commentFieldRequired: true,
            accept: acceptFunction,
            reject: () => { },
            placeholder: 'Type new name',
            length: '50',
            type: 'Update Pump Schedule Name'
        });

        const dialogInst = this.confirmationDialogService.componentRef.instance;
        dialogInst.configs.errorMessages = [
            new ErrorMessageModel(ERROR_TYPE.REQUIRED, 'Pump schedule name can\'t be blank')
        ];
    }

    get getNamePumpSchedule() {
        const model = this._scheduleStateFactory.getModel();
        if (model) {
            return model.name;
        }

        return "Pump Schedule 01";// default pump schedule name
    }

    onStageAlign(stageComponent: any) {
        if (stageComponent.stage) {
            const blockPosition = stageComponent.isAlignNearest ? 'nearest' : 'center';
            setTimeout(() => stageComponent.stage.scrollIntoView({ behavior: 'smooth', block: blockPosition }), 0);
        }
    }
}

export class Well {
  id: string;
  rig: string;
  rigName: string;
  wellId: string;
  wellName: string;
  wellNumber: string;
  customer: string;
  customerName: string;
  latitudeDegrees: number;
  latitudeDirection: string;
  latitudeMinutes: number;
  latitudeSeconds: string;
  longitudeDegrees: number;
  longitudeDirection: string;
  longitudeMinutes: number;
  longitudeSeconds: string;
  isOffshore: boolean;
  deviationStatus: number;
  deviationStatusDescription: string;
  waterDepth: number;
  field: string;
  lease: string;
  site: string;
  contactFullName: string;
  contactPhoneNumber: string;
  contactAddress1: string;
  canEdit: boolean;
  isLand: boolean;
  isVeritical: boolean;
  contractId?: string;
  /**
   *
   */
  constructor() {
    this.id = "";
    this.canEdit = true;
    this.contactFullName = "";
    this.contactPhoneNumber = "";
    this.customer = "";
    this.customerName = "";
    this.deviationStatus = null;
    this.deviationStatusDescription = "";
    this.field = "";
    this.isOffshore = false;
    this.latitudeDegrees = null;
    this.latitudeDirection = null;
    this.latitudeMinutes = null;
    this.latitudeSeconds = "";
    this.lease = "";
    this.longitudeDegrees = null;
    this.longitudeDirection = null;
    this.longitudeMinutes = null;
    this.longitudeSeconds = "";
    this.rig = null;
    this.rigName = "";
    this.site = "";
    this.waterDepth = null;
    this.wellId = "";
    this.wellName = "";
    this.wellNumber = "";
    this.isLand = false;
    this.isVeritical = true;
    this.isOffshore = false;
    this.contractId = null;
  }

  public static fromData(data: any) {
    return { ...data };
  }

  public static isValidNumber(value: string | number) {
    if (typeof value == "string") {
      return false;
    }

    return value != null && value != undefined;
  }
}

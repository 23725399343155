<div class="form-subtitle-section">
  {{header}}
  <a class="view-less" (click)="toggleExpanded()">{{ isTableExpanded ? 'View All': 'View Less'}}</a>
</div>  <strong *ngIf="hasScope3access && isBlend" class="ml-5">Total Blend CO2e =   </strong>
<span *ngIf="(isVisible$('totalPlannedCO2e') | async) && isBlend && hasScope3access" class="unit-value" [apiValue]="totalBlendCo2e" [unitType]="UnitType.Weight"
      [formatNum]="2" [shouldShowFormatNum]="true"></span>
<span *ngIf="(isVisible$('totalActualCO2e') | async) && isBlend && hasScope3access" class="unit-value" [apiValue]="totalBlendActualCo2e" [unitType]="UnitType.Weight"
      [formatNum]="2" [shouldShowFormatNum]="true"></span>

<p-table [hidden]="isTableExpanded" [value]="materials" styleClass="no-fixed-table">
  <ng-template pTemplate="header">
    <tr class="pump-fluid-material-header">
      <th>iFacts Material Name</th>
      <th *ngIf="isVisible$('concentration') | async">Concentration</th>
      <th *ngIf="isVisible$('lotNo') | async">Lot #</th>
      <th>Planned Qty</th>
      <th *ngIf="isVisible$('loadoutVolume') | async">{{ (isOffshoreJob$ | async) ? 'Required on Rig' : 'Load Out Qty' }}</th>
      <th *ngIf="isVisible$('overrideVolume') | async" width="150">Override Qty</th>
      <th *ngIf="isVisible$('actualQty') | async">Actual Qty</th>
      <th *ngIf="isVisible$('overrideMixingProcedure') | async" width="50">Mix</th>
      <th>SAP Material</th>
      <th *ngIf="isVisible$('displayName') | async" [ngClass]="{'display-name-cp1': isCPviewed(1), 'display-name-cp2': isCPviewed(2), 'display-name-job-edit': isCPviewed()}">Display Name</th>
      <th *ngIf="isVisible$('dryVolume') | async" style="width: 110px;">Dry Volume</th>
      <th *ngIf="isVisible$('totalCOGS') | async" style="width: 110px;">Material COGS</th>
      <th *ngIf="(isVisible$('totalPlannedCO2e') | async) && hasScope3access && !isBlend" style="width: 110px;">Total CO2e</th>
      <th *ngIf="(isVisible$('totalActualCO2e') | async) && hasScope3access && !isBlend" style="width: 110px;">Actual CO2e</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-material>
    <tr [formGroup]="getForm(material)">
      <td class="vertical-align-middle">
        {{ material.fluidMaterialName }}
      </td>
      <td *ngIf="isVisible$('concentration') | async" class="vertical-align-middle">
        <div *ngIf="!isMixWater(material); else mixWaterConcentration"
             [class.exception-disable-item]="enablingCPStates$ | async"
             class="flx-row no-gutters concen-input-group">
          <span>{{ material.concentration | number:'.3'}} {{ material.concentrationUnit }}</span>
        </div>
        <ng-template #mixWaterConcentration>
          <span formControlName="concentration" dUnitValue={{UnitType.LiquidsSolidsRatio}} dUnitWithName="true"></span>
        </ng-template>
      </td>
      <td *ngIf="isVisible$('lotNo') | async" class="vertical-align-middle">
        {{ material.lotNo }}
      </td>
      <td class="vertical-align-middle">
        <span formControlName="plannedVolume" [dUnitValue]="material.plannedVolumeUnit" dUnitWithName="true"></span>
      </td>
      <td *ngIf="isVisible$('loadoutVolume') | async" class="vertical-align-middle">
        <span formControlName="loadoutVolume" [dUnitValue]="material.loadoutVolumeUnit" dUnitWithName="true"></span>
      </td>
      <td *ngIf="isVisible$('overrideVolume') | async" class="vertical-align-middle">
        <div *ngIf="isOverrideVolumeEditable">
          <div class="flx-ctn-row" [ngClass]="classOnCP2State$ | async">
            <input-unit formControlName="overrideVolume"
                        class="flx-itm-grow m-r-10"
                        [unit]="material.loadoutVolumeUnit"
                        [canUserChangeUnit]="false"
                        [lockableElement]="'job_completed'"
                        [disabled]="(classOnCP2State$ | async) == 'custom-disable'"
                        pKeyFilter="pnum">
            </input-unit>
            <span class="flx-itm-center" dUnitName={{material.loadoutVolumeUnit}}></span>
          </div>
          <error-display formControlName="overrideVolume" [errorMsg]="errorMessages.overrideVolume"></error-display>
        </div>
        <div *ngIf="!isOverrideVolumeEditable">
          <span class="unit-value" [apiValue]="material.overrideVolume" [unitType]="material.loadoutVolumeUnit"></span>
        </div>
      </td>
      <td *ngIf="isVisible$('actualQty') | async" class="vertical-align-middle">
        <div class="flx-ctn-row" [ngClass]="classOnCP2State$ | async">
          <input-unit formControlName="actualQty"
                      class="flx-itm-grow m-r-10"
                      [ngClass]="{'disabled-qt': (classOnCP2State$ | async) === 'custom-disable'}"
                      [unit]="material.loadoutVolumeUnit"
                      [canUserChangeUnit]="false"
                      [lockableElement]="'job_completed'"
                      [disabled]="(classOnCP2State$ | async) === 'custom-disable'"
                      pKeyFilter="pnum">
          </input-unit>
          <span class="flx-itm-center" dUnitName={{material.loadoutVolumeUnit}}></span>
        </div>
      </td>
      <td *ngIf="isVisible$('overrideMixingProcedure') | async" class="vertical-align-middle">
        <ng-container *ngIf="isDry(material) || isLiquidUnit(material)">
          <div [lockableElement]="'overrideMixingProcedure'">
            <pump-mix-on-the-fly [material]="material" [disabled]="!isMixEditable" [isLiquid]="isLiquidUnit(material)" formControlName="overrideMixingProcedureId" [options]="getDropdownMixItems$(material) | async"></pump-mix-on-the-fly>
          </div>
        </ng-container>
      </td>
      <td class="vertical-align-middle">
        <div *ngIf="isSapMaterialEditable" [lockableElement]="'FluidDetails_actualQty'">
          <sap-material [disabled]="disablingJobStates"
                        [class.exception-disable-item]="enablingCPStates$ | async"
                        [generalMaterialId]="getIFactsMaterialIdControl(material)"
                        [sapMaterialNumber]="getSapMaterialNumberControl(material)"
                        [sapMaterialName]="getSapMaterialNameControl(material)"
                        useTextArea="true"
                        (onChangeSAP)="onUpdateSapMaterial(material, $event)"
                        (onClearSAP)="onUpdateSapMaterial(material, $event)">
          </sap-material>
          <error-display formControlName="sapMaterialNumber" [errorMsg]="errorMessages.sapMaterialNumber"></error-display>
        </div>
        <div *ngIf="!isSapMaterialEditable">
          <span class="unit-value">{{ material.sapMaterialName }}</span>
        </div>
      </td>
      <td *ngIf="isVisible$('displayName') | async" class="vertical-align-middle">
        {{ material.sapMaterialDisplayName }}
      </td>
      <td *ngIf="isVisible$('dryVolume') | async" class="vertical-align-middle">
        <ng-container *ngIf="isDry(material)">
          <div *ngIf="!(bulkDensityAvailable$(material) | async)" class="warning">No Bulk Density available</div>
          <span *ngIf="bulkDensityAvailable$(material) | async" formControlName="dryVolume" dUnitValue={{UnitType.DryVolume}} dUnitWithName="true"></span>
        </ng-container>
      </td>
      <td *ngIf="isVisible$('totalCOGS') | async" class="vertical-align-middle">
        <div *ngIf="isExistingJob">
          <div *ngIf="cogsUomIncompatible$(material) | async" class="warning">Units conversion failure</div>
          <div *ngIf="!(cogsAvailable$(material) | async) && !(cogsUomIncompatible$(material) | async)" class="warning">No COGS available</div>
        </div>
        <span class="unit-value" *ngIf="cogsAvailable$(material) | async">${{ getCogs$(material) | async | number: '.2-2' }}</span>
      </td>
      <td *ngIf="(isVisible$('totalPlannedCO2e') | async) && hasScope3access  && !isBlend" class="vertical-align-middle">
        <span *ngIf="isPlannedScope3Co2eNotNull(material)" formControlName="plannedScope3Co2e" dUnitValue={{UnitType.Weight}} dUnitWithName="true" dUnitDecimalPlaces="0"></span>
        <span *ngIf="!isPlannedScope3Co2eNotNull(material)">{{getPlannedScope3(material)}}</span>
      </td>
      <td *ngIf="(isVisible$('totalActualCO2e') | async) && hasScope3access  && !isBlend" class="vertical-align-middle">
        <span *ngIf="isActualScope3Co2eNotNull(material)" formControlName="actualScope3Co2e" dUnitValue={{UnitType.Weight}} dUnitWithName="true" dUnitDecimalPlaces="0"></span>
        <span *ngIf="!isActualScope3Co2eNotNull(material)">{{getActualScope3(material)}}</span>
      </td>
    </tr>
  </ng-template>
</p-table>
<div *ngIf="fluidType == 'Supplemental' && materials?.length === 0">
  <p class="no-content">There currently are no Supplemental Materials included for this fluid.</p>
</div>

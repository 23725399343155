import { DeadVolumeFluidType } from 'libs/constants';
import { calculateBulkCementByLoadOutVolume, calculateLoadOutVolumeByBulkCement, calculateStageWater } from 'libs/shared/calculations';
import { combineLatest, concat, merge, Observable, of } from 'rxjs';
import { filter, map, shareReplay, skip, switchMap, switchMapTo, take } from 'rxjs/operators';
import { EventCalculator } from './event-calculator';
import { FluidMaterialCalculator } from './material-calculator';
import { round } from 'lodash';

export class StageFluidCalculator {

    private static BBL_TO_GAL_FACTOR: number = 42;

    private static GAL_TO_CC_FACTOR: number = 1.0 / 0.00026417205;

    public readonly plannedVolume$: Observable<number> = this._events$
        .pipe(
            switchMap(events =>
                combineLatest(events.map(e => e.volume$))
                    .pipe(
                        map(eventsVolumes => {

                            const v = eventsVolumes.sum(v => this._toNumberOrDefault(v, 0));
                            return v;
                        })
                    )
            ),
            shareReplay()
        );

    private readonly _nonShutdownDuration$: Observable<number> = this._events$
        .pipe(
            switchMap(events =>
                combineLatest(events.map(e => e.nonShutdownDuration$))
                    .pipe(
                        map(eventsDurations => {

                            const d = eventsDurations.sum();
                            return d;
                        })
                    )
            ),
            shareReplay()
        );

    public readonly duration$: Observable<number> = this._events$
        .pipe(
            switchMap(events =>
                combineLatest(events.map(e => e.duration$))
                    .pipe(
                        map(eventsDurations => {

                            const d = eventsDurations.sum();
                            return d;
                        })
                    )
            ),
            shareReplay()
        );

    public readonly avgRate$: Observable<number> = this._events$
        .pipe(
            switchMapTo(
                combineLatest([
                    this.plannedVolume$,
                    this._nonShutdownDuration$
                ])
                    .pipe(
                        map(([eventVolume, eventDuration]) => {

                            const r = this._calcAvgRate(eventVolume, eventDuration);
                            return r;
                        })
                    )
            ),
            shareReplay()
        );

    public readonly topOfFluid$: Observable<number> = this._events$
        .pipe(
            switchMap(events =>
                combineLatest(events.map(e => e.topOfFluid$))
                    .pipe(
                        map(eventsTopOfFluid => {

                            const tof = eventsTopOfFluid.filter(etof => !!etof || etof === 0).min();
                            return tof;
                        })
                    )
            ),
            shareReplay()
        );

    private readonly _loadoutByBulkCement$: Observable<number> = combineLatest([
        this._manualBulkCement$,
        this._yield$
    ])
        .pipe(
            map(([bulkCement, yieldValue]) => {

                const lvbc = calculateLoadOutVolumeByBulkCement(bulkCement, yieldValue);
                return lvbc;
            }),
            shareReplay()
        );

    private readonly _loadoutByDeadVolume$: Observable<number> =
        combineLatest([
            this.plannedVolume$,
            this.deadVolumeType$,
            this.deadVolume$
        ])
            .pipe(
                map(([plannedVolume, deadVolumeType, deadVolume]) => {

                    if (deadVolumeType === DeadVolumeFluidType.CementSlurry && deadVolume) {

                        return plannedVolume + deadVolume;
                    }

                    return plannedVolume;
                }),
                filter(volume => !!volume),
                shareReplay()
            );

    public readonly loadoutVolume$: Observable<number> =
        concat(
            // For the first time take either manual loadout volume (saved), or calculate it if no saved
            combineLatest([
                this._manualLoadoutVolume$.pipe(take(1)),
                this.plannedVolume$.pipe(take(1))
            ])
                .pipe(
                    map(([manualLoadoutVolume, plannedVolume]) => {

                        if (!!manualLoadoutVolume) {

                            return manualLoadoutVolume;
                        }
                        return plannedVolume;
                    })
                ),
            // Later either accept manual changes (control values) or recalculate when dependent values change
            merge(
                this._manualLoadoutVolume$.pipe(skip(1)),
                combineLatest([
                    merge(
                        this._loadoutByBulkCementTrigger$.pipe(switchMap(_ => this._loadoutByBulkCement$)),
                        merge(
                            this.plannedVolume$.pipe(skip(1)),
                            this._loadoutByDeadVolumeTrigger$
                        ).pipe(switchMap(_ => this._loadoutByDeadVolume$))
                    ),
                    this.plannedVolume$
                ])
                    .pipe(
                        map(([calculatedLoadoutVolume, plannedVolume]) => {
                            
                            return !!calculatedLoadoutVolume || calculatedLoadoutVolume === 0
                                ? calculatedLoadoutVolume
                                : plannedVolume;
                        })
                    ),
            )
        )
            .pipe(
                shareReplay()
            );

    public readonly actualVolume$: Observable<number> =
        concat(
            // If no saved stage actual volume, take planned volume for the first time.
            combineLatest([
                this._manualActualVolume$,
                this.plannedVolume$
            ])
                .pipe(
                    map(([manualActualVolume, plannedVolume]) => {

                        return (!manualActualVolume && manualActualVolume !== 0) ? plannedVolume : manualActualVolume;
                    }),
                    take(1)
                ),
            // Later take either value - manually set or calculated - which is latest.
            merge(
                this._manualActualVolume$.pipe(skip(1)),
                this.plannedVolume$.pipe(skip(1))
            )
        )
            .pipe(
                shareReplay()
            );

    public readonly bulkCement$: Observable<number> =
        concat(
            // For the first time take either manual bulk cement (saved), or calculate it if no saved
            combineLatest([
                this._manualBulkCement$,
                this.loadoutVolume$,
                this.plannedVolume$,
                this._yield$,
                this._originYield$
            ])
                .pipe(
                    filter(([manualBulkCement, loadoutVolume, plannedVolume, yieldValue]) => {

                        // do not emit if cannot calculate
                        return !!manualBulkCement
                            || ((!!loadoutVolume || !!plannedVolume) && !!yieldValue);
                    }),
                    map(([manualBulkCement, loadoutVolume, plannedVolume, yieldValue, originYield]) => {

                        if (round(yieldValue, 12) === round(originYield, 12) && (!!manualBulkCement || manualBulkCement === 0)) {
                            return manualBulkCement;
                        }

                        const bc = calculateBulkCementByLoadOutVolume(
                            loadoutVolume,
                            plannedVolume,
                            yieldValue
                        );

                        return bc;
                    }),
                    take(1)
                ),
            // Later either accept manual changes (control values) or recalculate when dependent values change
            merge(
                this._manualBulkCement$.pipe(skip(1)),              // skip the first emission (setting from model)
                combineLatest([
                    merge(
                        this._manualLoadoutVolume$.pipe(skip(1)),
                        this._loadoutByDeadVolume$.pipe(skip(1)),
                        // after selecting a new Fluid, we need to recalculate the Bulk Cement
                        // based on the existing Load Out Qty and the Yield obtained from the newly linked Fluid
                        this._yield$.pipe(skip(1), switchMap(_ => this.loadoutVolume$))
                    ),
                    this.plannedVolume$,
                    this._yield$
                ])
                    .pipe(
                        map(([loadoutVolume, plannedVolume, yieldValue]) => {

                            const bc = calculateBulkCementByLoadOutVolume(
                                loadoutVolume,
                                loadoutVolume ?? plannedVolume,
                                yieldValue
                            );

                            return bc;
                        })
                    )
            )
        )
            .pipe(
                shareReplay()
            );

    private readonly _stageWater$: Observable<number> =
        combineLatest([
            this.loadoutVolume$,
            this.plannedVolume$,
            this._yield$,
            this._mixWater$
        ])
            .pipe(
                map(([loadoutVolume, plannedVolume, yieldVal, mixWater]) => {
                    const sw = calculateStageWater(loadoutVolume, plannedVolume, yieldVal, mixWater);
                    return sw;
                }),
                shareReplay()
            );

    private readonly _initialWaterQty$: Observable<number> =
        combineLatest([
            this.deadVolume$,
            this.plannedVolume$,
            this._yield$,
            this._mixWater$
        ])
            .pipe(
                map(([_, plannedVolume, yieldVal, mixWater]) => {
                    const sw = calculateStageWater(null, plannedVolume, yieldVal, mixWater);
                    return sw;
                }),
                shareReplay()
            );

    public readonly stageWaterGal$: Observable<number> = this._initialWaterQty$
        .pipe(
            map(stageWater => {
                return stageWater * StageFluidCalculator.BBL_TO_GAL_FACTOR;
            }),
            shareReplay()
        );

    public readonly stageWaterVolumeCC$: Observable<number> = this.stageWaterGal$
        .pipe(
            map(stageWaterGal => {
                return stageWaterGal * StageFluidCalculator.GAL_TO_CC_FACTOR;
            }),
            shareReplay()
        );

    public readonly stageWaterMassGrm$: Observable<number> = 
        combineLatest([
            this.stageWaterGal$,
            this._waterDensity$
        ]) 
        .pipe(
            map(([stageWaterGal, waterDensity]) => {
                return stageWaterGal * StageFluidCalculator.GAL_TO_CC_FACTOR * waterDensity;
            }),
            shareReplay()
        );

    public readonly totalVolumeCC$: Observable<number> = this._materials$
        .pipe(
            switchMap(materials =>
                combineLatest([
                    combineLatest(materials.filter(m => m.isAdditive).map(m => m.volumeCC$)),
                    this.stageWaterVolumeCC$
                ])
            ),
            map(([materialVolumeCC, stageWaterVolumeCC]) => {
                
                return materialVolumeCC.filter(v => !!v).sum() + stageWaterVolumeCC;
            }),
            shareReplay()
        );

    public readonly totalMassGrm$: Observable<number> = this._materials$
        .pipe(
            switchMap(materials =>
                combineLatest([
                    combineLatest(materials.filter(m => m.isAdditive).map(m => m.massGrm$)),
                    this.stageWaterMassGrm$
                ])
            ),
            map(([materialMassGrm, stageWaterMassGrm]) => {
                
                return materialMassGrm.filter(v => !!v).sum() + stageWaterMassGrm;
            }),
            shareReplay()
        );

    public readonly totalVolumeWithStageWater$: Observable<number> = this._materials$
        .pipe(
            switchMap(materials =>
                combineLatest([
                    combineLatest(materials.map(m => m.loadoutVolumeBbl$)),
                    this._stageWater$
                ])
            ),
            map(([materialLoadoutVolumes, stageWater]) => {

                const tvsw = materialLoadoutVolumes.filter(v => !!v).sum() + stageWater;
                return tvsw;
            }),
            shareReplay()
        );

    public readonly stageWater$: Observable<number> =
        combineLatest([
            this._stageWater$,
            this.totalVolumeWithStageWater$,
            this.deadVolumeType$,
            this.deadVolume$
        ])
            .pipe(
                map(([stageWater, totalVolume, deadVolumeType, deadVolume]) => {

                    if (deadVolumeType === DeadVolumeFluidType.CementSlurry) {

                        return stageWater;
                    }

                    return stageWater + stageWater * deadVolume / totalVolume;
                }),
                shareReplay()
            );

    public readonly placementTime$: Observable<number> = combineLatest([
            this._remainingStagesDurations$,
            this._events$
            .pipe(
                switchMap(events =>
                    combineLatest(events.map(e => e.isShutdown$))
                )),
            this._events$
            .pipe(
                switchMap(events =>
                    combineLatest(events.map(e => e.duration$))
                ))
        ])
        .pipe(
            map(([remainingStagesDurations, isShutdowns, durations]) => {
                
                const firstNonShutDownIndex = isShutdowns.findIndex(s => !s);
                const firstShutdownDurations = durations.slice(0, firstNonShutDownIndex >= 0 ? firstNonShutDownIndex : isShutdowns.length);

                const pt = remainingStagesDurations.sum() - firstShutdownDurations.sum(x => x);

                return Math.round(pt);
            }),
            shareReplay()
        );

    public readonly minThickeningTime$: Observable<number> =
        combineLatest([
            this.placementTime$,
            this._scheduledShutdown$,
            this._targetSafetyFactor$,
            this.specificShutdownTime$
        ])
            .pipe(
                map(([placementTime, scheduledShutdown, targetSafetyFactor, specificShutdownTime]) => {

                    const mtt = Number(placementTime) + Number(scheduledShutdown) + Number(targetSafetyFactor) + Number(specificShutdownTime);
                    return Math.round(mtt);
                }),
                shareReplay()
            );

    public readonly actualSafety$: Observable<number> =
        combineLatest([
            this.placementTime$,
            this.thickeningTime$
        ])
            .pipe(
                map(([placementTime, thickeningTime]) => {

                    const sfty = thickeningTime <= placementTime ? null : thickeningTime - placementTime;
                    return sfty;
                }),
                shareReplay()
            );

    public readonly dryWeight$: Observable<number> = this._materials$
        .pipe(
            switchMap(materials => {

                if (materials.length === 0) {

                    return of([]);
                }

                return combineLatest(materials.map(m => m.dryWeight$));
            }),
            map(materialsWeights => {

                return materialsWeights.sum();
            })
        );

    public readonly dryVolume$: Observable<number> = this._materials$
        .pipe(
            switchMap(materials => {

                if (materials.length === 0) {

                    return of([]);
                }

                return combineLatest(materials.map(m => m.dryVolume$));
            }),
            map(materialsVolumes => {

                return materialsVolumes.map(cv => cv.Value).sum();
            })
        );

    public readonly plannedScope3Co2e$: Observable<number> = this._materials$
        .pipe(
            switchMap(materials => {

                if (materials.length === 0) {

                    return of([]);
                }

                return combineLatest(materials.map(m => m.plannedScope3Co2e$));
            }),
            map(scope3s => {

                return scope3s.sum();
            })
        );

    public readonly actualScope3Co2e$: Observable<number> = this._materials$
        .pipe(
            switchMap(materials => {

                if (materials.length === 0) {

                    return of([]);
                }

                return combineLatest(materials.map(m => m.actualScope3Co2e$));
            }),
            map(scope3s => {

                return scope3s.sum();
            })
        );

    public constructor(

        private readonly _events$: Observable<EventCalculator[]>,

        private readonly _materials$: Observable<FluidMaterialCalculator[]>,

        private readonly _manualLoadoutVolume$: Observable<number>,

        private readonly _loadoutByBulkCementTrigger$: Observable<boolean>,

        private readonly _loadoutByDeadVolumeTrigger$: Observable<boolean>,

        private readonly _manualBulkCement$: Observable<number>,

        public readonly deadVolume$: Observable<number>,

        public readonly deadVolumeType$: Observable<DeadVolumeFluidType>,

        private readonly _manualActualVolume$: Observable<number>,

        private readonly _yield$: Observable<number>,

        private readonly _originYield$: Observable<number>,

        private readonly _mixWater$: Observable<number>,

        private readonly _remainingStagesDurations$: Observable<number[]>,

        public readonly thickeningTime$: Observable<number>,

        public readonly specificShutdownTime$: Observable<number>,

        private readonly _scheduledShutdown$: Observable<number>,

        private readonly _targetSafetyFactor$: Observable<number>,

        private readonly _waterDensity$: Observable<number>
    ) {
    }

    public calcStageFluidCost(materialsCogs: number[]): number {

        if (materialsCogs.every(mc => !!mc || mc === 0)) {

            const mc = materialsCogs.sum();
            return mc;
        }

        return null;
    }

    private _calcAvgRate(volume: number, duration: number): number {

        let rate = null;

        if (duration > 0) {

            rate = volume / duration;
        }

        return rate;
    }

    private _toNumberOrDefault(val: number, defVal: number): number {

        let numberVal = Number(val);
        if (!numberVal || isNaN(numberVal)) {

            numberVal = defVal;
        }

        return numberVal;
    }
}
